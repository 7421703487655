import React, { useState, useEffect, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { doc, collection, addDoc, query, where, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions";
import { db, functions } from '../firebase/auth.js'; // Assumed paths to your Firebase configuration
import { UserContext } from '../hooks/UserContext.js';

const CheckoutContext = createContext();

export const useCheckout = () => useContext(CheckoutContext);

export const CheckoutButton = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  const [isUserSmallBatchDevs, setIsUserSmallBatchDevs] = useState(false);
  const [posts, setPosts] = useState([]);
  const [doesNotHaveSubs, setDoesNotHaveSubs] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (currentUser) {
      // Check for subscriptions
      const subsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
      const unsubscribe = onSnapshot(subsRef, (snapshot) => {
        // Assuming we want to check if there are any active or trialing subscriptions
        const hasSubs = snapshot.docs.some(doc =>
          ['trialing', 'active'].includes(doc.data().status)
        );
        setDoesNotHaveSubs(!hasSubs);
      });

      // Cleanup listener on unmount
      return () => unsubscribe();
    }
  }, [currentUser]);

  useEffect(() => {
    // Fetch posts if the user is authenticated and is a SmallBatchDev
    if (isUserSmallBatchDevs) {
      // Replace with your logic to fetch posts
      // setPosts(fetchedPosts);
    }
  }, [isUserSmallBatchDevs]);

  const editPost = (postUid) => {
    navigate(`/edit/${postUid || 'new'}`); // Assuming 'new' is a valid path for creating posts
  };

  const readPost = (postUid) => {
    navigate(`/post/${postUid}`);
  };

  const addContactToDatabase = async (email, firstName) => {
    // Firebase logic to add contact
    const contactRef = doc(db, `contacts/${email}`);
    // Assuming a set function that takes a document reference and the data
    // set(contactRef, { email, firstName });
  };

  const sendToCheckout = async (price) => {
    console.log(price)
    if (currentUser) {
      const checkoutRef = collection(db, 'customers', currentUser.uid, 'checkout_sessions');
      const docRef = await addDoc(checkoutRef, {
        // TODO: Replace the price key when you go live
        price: price, // TODO: Add your Stripe price ID here
        success_url: "https://stockfin.net/dashboard",
        cancel_url: "https://stockfin.net/",
      });

      onSnapshot(docRef, async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          console.log("Error in the snapshot checkoutButton", error)
        }

        if (sessionId) {
          console.log("Session id: ", sessionId)
          //TODO: Replace PK key with the official live key
          const stripe = await loadStripe("pk_live_51OhPX9E9WDW4fbdqnKrCxQca3ftVYYKrtyKI7vhbVEhcghwtBsqpV0xYeY8roSXyILoatqXj5CDWawO88neKTfwe00YdzLPBTN"); // TODO: Insert your public Stripe key
          stripe.redirectToCheckout({ sessionId });
        }
      });
    }
  };

  const sendToCustomerPortal = async () => {
    console.log("Sending to customer portal");
    
    // Corrected to use just the name of the Firebase Cloud Function
    const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    
    try {
        const { data } = await createPortalLink({ returnUrl: window.location.origin+"/dashboard" });
        window.location.assign(data.url);
    } catch (error) {
        console.error("Error sending to customer portal:", error);
    }
};



const getPremiumStatus = async () => {
  if (!currentUser || !currentUser.uid) {
    throw new Error("User is not logged in or invalid user data.");
  }

  const subscriptionsRef = collection(db, "customers", currentUser.uid, "subscriptions");
  const q = query(
    subscriptionsRef, 
    where("status", "in", ["trialing", "active"])
  );

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        console.log("Subscription snapshot", snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          console.log("No active or trialing subscriptions found");
          resolve({ active: false });
        } else {
          // Assuming the subscription data includes an 'invoices' array
          const subscriptions = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })).sort((a, b) => {
            const dateA = new Date(a.created.seconds * 1000); // Assuming 'created' is a Timestamp
            const dateB = new Date(b.created.seconds * 1000);
            return dateB - dateA; // Sort subscriptions by date descending
          });

          // Find the most recent subscription
          const latestSubscription = subscriptions[0];
          return resolve(latestSubscription)
          // console.log("Most recent subscription found:", latestSubscription);

          // // Sort invoices by date within the most recent subscription
          // if (latestSubscription.invoices && latestSubscription.invoices.length > 0) {
          //   const sortedInvoices = latestSubscription.invoices.sort((a, b) => {
          //     const dateA = new Date(a.created * 1000); // Assuming 'created' is a Unix timestamp
          //     const dateB = new Date(b.created * 1000);
          //     return dateB - dateA;
          //   });

          //   const latestInvoice = sortedInvoices[0];
          //   console.log("Most recent invoice found:", latestInvoice);
          //   resolve({ active: true, latestInvoice });
          // } else {
          //   resolve({ active: true, message: "No invoices found in the most recent subscription." });
          // }
        }
        unsubscribe();
      },
      reject
    );
  });
};

  return (
    <CheckoutContext.Provider value={{ sendToCheckout, sendToCustomerPortal, getPremiumStatus }}>
      {children}
    </CheckoutContext.Provider>
  );
};

