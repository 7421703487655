// src/components/StockInfo.js
import React from 'react';
import CandlestickChart from './candlestickchart.js'
// import CandlestickChart from './plotlycandle.js'

console.log(CandlestickChart);
const StockInfo = ({ stockData }) => {
  if (!stockData) return null;
  return (
    <div className="w-full transition duration-500 p-10 flex flex-col justify-center gap-10 ">
      <div className="mx-auto border-black rounded-lg px-5 py-2">
        <CandlestickChart data={stockData}/>
      </div>
    </div>
  );
};

export default StockInfo;
