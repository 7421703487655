import Home from "./Home.js";
import Login from "./pages/login.js";
import Register from "./pages/register.js";
import ForgotPass from "./pages/forgotpass.js";
import Dashboard from "./pages/dashboard.js";
import Verify from "./pages/verify.js"
import Account from "./components/account.js";
import Payment from "./pages/payment.js";
import { UserProvider } from './hooks/UserContext.js';
import { CheckoutButton } from "../src/components/checkoutButton.js"
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { BudgetDataProvider } from './hooks/budgetDataContext.js';


function App() {
  return (
    <UserProvider>
      <BudgetDataProvider>
        <BrowserRouter>
        <CheckoutButton>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path = "/verify" element = {<Verify />} />
            <Route path="/account" element = {<Account /> } />
            <Route path="/payment" element = {<Payment /> } />
          </Routes>
        </CheckoutButton>
        </BrowserRouter>
      </BudgetDataProvider>
    </UserProvider>
  );
}

export default App;
