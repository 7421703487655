import React, { useState, useEffect } from 'react';
import useDarkMode from '../hooks/useDarkMode.js';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from "react-router-dom";

const Sidebar = ({ menus, changePage, currentPage, closeMenu }) => {
  const navigate = useNavigate();
  const [theme, setTheme] = useDarkMode();
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sidebarWidth, setSidebarWidth] = useState(250); // Default sidebar width

  const redirectToHome= () => {
    navigate("/")
}

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Adjust the sidebar width based on the screen size
    if (windowWidth < 1025) { // Example breakpoint for smaller screens
      setSidebarWidth(200); // Adjusted width for smaller screens
    } else {
      setSidebarWidth(250); // Default width for larger screens
    }
  }, [windowWidth]);
  
  const setCurrentPage = (page) => {
    changePage(page);
  };

  const setMenu = () => {
    setIsOpen(currentIsOpen => {
      const newState = !currentIsOpen;
      closeMenu(newState);
      return newState;
    });
  };

  const isOpeningConfig = {
    tension: 800, // Configuration for opening
    friction: 120, // Adjust friction for opening
    immediate: true
  };
  
  const isClosingConfig = {
    tension: 800, // Lower tension for a slower start
    friction: 200, // Higher friction to slow down the movement more quickly
  };
  
  const sidebarStyles = useSpring({
    to: {
      transform: isOpen ? `translateX(0%)` : `translateX(-25%)`,
      width: isOpen ? sidebarWidth : 0, // Adjust 250 to your sidebar's open width in pixels
    },
    from: {
      transform: 'translateX(-100%)', // Start fully hidden
      width: 0, // Start with 0 width if you want the sidebar to grow from 0 to 250px
    },
    delay: isOpen ? 0 : 100,
    config: isOpen ? isOpeningConfig : isClosingConfig,
  });

  const simpleAnimation = useSpring({
    transform: isOpen ? `translateX(0)` : `translateX(-100%)`,
    immediate: true,
    config: { tension: 500, friction: 50 },
  });

  const menuButtonAnimation = useSpring({
    transform: isOpen ? 'translateX(-40px)' : 'translateX(0)', // Adjust the translateX value based on how much you want it to extrude
    config: { tension: 170, friction: 26 },
  });

  return (
    <animated.div style = {sidebarStyles} className={`h-screen pl-2 flex items-center`}>
    <animated.div style = {simpleAnimation} className={`h-[98%] absolute [will-change:transform] text-noto lg:w-48 xl:w-64 bg-blue-700 dark:bg-darkfin transition duration-500 rounded-xl text-white z-10 `}>
      {/* The closing tab */}
      <div className="flex flex-col justify-between h-full">
              <animated.div style={menuButtonAnimation} onClick = {setMenu} className='absolute cursor-pointer w-14 h-20 bg-blue-700 dark:bg-darkfin top-0 transition duration-500 rounded-r-xl -right-10 z-0 flex justify-center items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ml-4 transition duration-500 ${isOpen && "rotate-180"} `}><path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" /></svg></animated.div>
        <div className='w-full h-full'>
            <div className="text-xl flex mt-2 w-5/6 xl:w-3/4 flex-row  relative justify-between font-semibold p-4">
              <button className=' flex justify-center items-center right-3 h-2/3 rounded-xl w-1/6' onClick={() => setTheme(!theme)}>{!theme ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" /></svg>: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" /></svg>}</button>
              <div className='cursor-pointer hover:scale-110 duration-500' onClick={redirectToHome}>StockFin.</div>
            </div>
            <ul className={`space-y-20 mt-16 w-full flex flex-col items-center`}>
              {menus.map((menu, index) => (
                <li key={index} onClick = {() => setCurrentPage(menu.name)} className={`px-4 py-5 rounded-xl w-[90%] justify-center ${currentPage === menu.name ? "bg-white text-black hover:bg-none font-bold" : "hover:bg-blue-600 dark:hover:bg-blue-400 dark:hover:bg-opacity-20 "} transition duration-500 flex flex-row gap-2 cursor-pointer`}>
                    <span>{menu.name}</span>
                    <span>{menu.icon}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="p-4">
              <div className="w-full relative flex flex-col text-black font-semibold pb-4 px-4 rounded-xl">
                <div className='absolute inset-0 bg-gray-800 bg-opacity-25 rounded-xl'></div> 
                <div className='flex flex-col text-white  pb-10 pt-6 items-center text-center z-10 relative'> 
                  <div className='opacity-75'>Is there an issue with your dashboard?</div>
                  <div className='text-gray-200 pt-2 opacity-75'>Report it to an admin</div>
                </div>
                <button className='py-4 rounded-lg hover:scale-105 transition duration-300 bg-finblue z-10 opacity-85 relative text-white'>Contact Us</button>
              </div>
            </div>
        </div>
      </animated.div>
      </animated.div>
  );
};

export default Sidebar;
