import React from 'react';
import { BarChart, LineChart, AreaChart, Bar, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Brush, ResponsiveContainer } from 'recharts';
import ApexCharts from 'apexcharts'
import moment from 'moment';

const formatDate = (date) => {
    // Using moment.js for date formatting
    return moment(date).format('MM/DD/YYYY');
  };

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    const date = moment(payload.value).format('DD/MM/YYYY'); // Simplified format
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {date}
        </text>
      </g>
    );
  };

const CandlestickChart = ({ data }) => {
  // Transform data to fit the candlestick pattern if needed
  const transformedData = data.map(item => ({
    name: item.date, // Assuming you have a date field
    open: item.open,
    high: item.high,
    low: item.low,
    close: item.close,
    volume: item.volume,
  }));

  // Custom tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { open, close, high, low } = payload[0].payload;
      return (
        <div className="custom-tooltip bg-white dark:bg-darkfin dark:text-white shadow-lg p-3">
          <p className="label">{`Date: ${formatDate(label)}`}</p>
          <p>{`Open: $${open}`}</p>
          <p>{`High: $${high}`}</p>
          <p>{`Low: $${low}`}</p>
          <p>{`Close: $${close}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
    <ResponsiveContainer width="100%" height="100%" aspect={1.2}>
      <AreaChart
        width={730}
        height={350}
        data={transformedData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <defs>
          <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00a6df" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#00a6df" stopOpacity={.3}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={<CustomizedAxisTick />} tickFormatter={formatDate} height={100}/>
        <YAxis domain={['auto', 'auto']} />
        <Tooltip content={<CustomTooltip />} />
        <ReferenceLine y={0} stroke="#000" />
        {/* Here you might need to adjust the bar size and fill based on your preference */}
        {/* <Area type="monotone" dataKey="high" fill="#8884d8" /> */}
        <Area type="monotone" dataKey="open" stroke="#00a6df" strokeWidth={2} fill="url(#colorPrice)" />
        {/* <Area type="monotone" dataKey="low" fill="#32891A" /> */}
        {/* <Area type="monotone" dataKey="close" fill="#B473F3" /> */}
        <Brush dataKey="date" height={20} stroke="#00a6df" />
        {/* Other bars for open, low, and close can be added similarly */}
      </AreaChart>
    </ResponsiveContainer>
    </div>
  );
};

export default CandlestickChart;


