import { useState, useEffect, useContext } from "react";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/auth.js';
import Logo from "../images/LoginLogoLight.png";
import LogoDark from "../images/logo_dark.png"
import { useNavigate } from "react-router-dom";
import useDarkMode from "../hooks/useDarkMode.js";
import { UserContext } from '../hooks/UserContext.js'; // Adjust the import path as necessary

export default function Login() {
    const [loginPassword, setLoginPassword] = useState("");
    const [loginEmail, setLoginEmail] = useState("");
    const provider = new GoogleAuthProvider();
    const [theme, setTheme] = useDarkMode();
    const navigate = useNavigate();
    const { currentUser, userName, setUserName, logout, googleAuth  } = useContext(UserContext);

    useEffect(() => {
        console.log(theme, "Sign in")
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                navigate("/"); // Redirect to home or dashboard if already logged in
            }
        });
    }, [navigate]);

    const login = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            console.log("/"); // Redirect on successful login
        } catch (error) {
            console.log(error.message);
        }
    };


    const googleSignIn = () => {
        googleAuth()
    }

    return (
        <>
           <div className="flex h-screen relative bg-darkfin dark:bg-inkfin items-center justify-center">
           <div className="absolute top-0 left-0 animate-fade-up flex"><img className="w-0 md:w-1/3 p-2 ml-2" src={LogoDark} alt="StockFin Logo" /></div>
                <div className="animate-fade-up relative z-10 bg-white dark:bg-[#1f2937] rounded-lg shadow-xl lg:w-1/3 w-5/6 h-2/3 border-gray-100 ">
                    <div className="absolute inset-x-0 w-full top-10"><div className="font-comfortaa mx-auto w-full font-bold text-4xl text-center dark:text-gray-100 rounded-md">Welcome!</div></div>
                    <div className="flex pt-32 justify-center items-center h-full">
                        <div className="flex flex-col justify-center w-2/3">
                            <div className="py-5 w-full"> <input className="w-full font-comfortaa border-b-2 outline-none bg-transparent dark:text-white" placeholder="Email" onChange={(e) => {setLoginEmail(e.target.value)}}/> </div>
                            <div className="py-5 w-full"> <input className="w-full font-comfortaa border-b-2 outline-none bg-transparent dark:text-white" placeholder="Password" onChange={(e) => {setLoginPassword(e.target.value)}} /> </div>
                            <button className="transform motion-safe:hover:scale-110 duration-500 text-white dark:text-black bg-inkfin dark:bg-finblue font-comfortaa w-full rounded-full p-2 text-center dark:text-gray-100" onClick = {login}>Sign in</button>
                            <div className="static float-left"><a href="/forgot-password"><div className="mt-4 font-comfortaa ml-2 text-sm dark:text-gray-100">Forgot Password?</div></a></div>
                            
                            <div className="flex mt-14 h-1/2 justify-center md:h-full scale-[95%] sm:scale-100 w-full ">
                                <button className="flex flex-row px-2 py-1 w-full md:2/3 lg:w-5/6 transform motion-safe:hover:scale-110 duration-500 bg-inkfin text-white rounded-lg justify-center shadow-lg" onClick={googleSignIn}>
                                    <div className="content-fill" ><svg className="w-3/4 fill-gray-100" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50"><path d="M 26 2 C 13.308594 2 3 12.308594 3 25 C 3 37.691406 13.308594 48 26 48 C 35.917969 48 41.972656 43.4375 45.125 37.78125 C 48.277344 32.125 48.675781 25.480469 47.71875 20.9375 L 47.53125 20.15625 L 46.75 20.15625 L 26 20.125 L 25 20.125 L 25 30.53125 L 36.4375 30.53125 C 34.710938 34.53125 31.195313 37.28125 26 37.28125 C 19.210938 37.28125 13.71875 31.789063 13.71875 25 C 13.71875 18.210938 19.210938 12.71875 26 12.71875 C 29.050781 12.71875 31.820313 13.847656 33.96875 15.6875 L 34.6875 16.28125 L 41.53125 9.4375 L 42.25 8.6875 L 41.5 8 C 37.414063 4.277344 31.960938 2 26 2 Z M 26 4 C 31.074219 4 35.652344 5.855469 39.28125 8.84375 L 34.46875 13.65625 C 32.089844 11.878906 29.199219 10.71875 26 10.71875 C 18.128906 10.71875 11.71875 17.128906 11.71875 25 C 11.71875 32.871094 18.128906 39.28125 26 39.28125 C 32.550781 39.28125 37.261719 35.265625 38.9375 29.8125 L 39.34375 28.53125 L 27 28.53125 L 27 22.125 L 45.84375 22.15625 C 46.507813 26.191406 46.066406 31.984375 43.375 36.8125 C 40.515625 41.9375 35.320313 46 26 46 C 14.386719 46 5 36.609375 5 25 C 5 13.390625 14.386719 4 26 4 Z"></path></svg></div>
                                    <h1 className="place-self-center font-comfortaa dark:text-gray-100">Sign in with <span className="text-blue-500">G</span><span className="text-red-500">o</span><span className="text-yellow-500">o</span><span className="text-blue-500">g</span><span className="text-green-500">l</span><span className="text-red-500">e</span></h1>
                            </button>
                        </div>
                        </div> 

                    </div>

                    <div className="absolute bottom-2 font-comfortaa inset-x-0 text-center dark:text-gray-100 text-sm">
                        <p>Dont have an account? <a href="/register" className="underline">Sign up</a></p>
                    </div>
                </div>  
            </div>
        </>
    );
}
