import React, {useState, useEffect} from 'react'

function Loading() {

    const tips = [
    "Tip: Did you know that you can pin your favorite stocks in Stockboard?",
    "Tip: You can create a new budget manually, or with the help of Fin!",
    "Tip: Please don't make Fin mad, he gets hyper."
    ];
    const colors = [
        "from-pink-500 to-orange-500",
        "from-green-500 to-blue-500",
        "from-purple-500 to-pink-500",
        "from-red-500 to-yellow-500",
        "from-blue-500 to-indigo-500"
      ];
      const [tipIndex, setTipIndex] = useState(0);

      useEffect(() => {
        const intervalId = setInterval(() => {
          setTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
        }, 4000); // change the tip and background color every 4 seconds
    
        return () => clearInterval(intervalId);
      }, []);
    
      return (
        <div className={`flex flex-col relative items-center justify-center w-full h-full bg-gradient-to-r from-blue-500 to-indigo-500 dark:from-inkfin dark:to-darkfin transition duration-500 text-white text-center p-4 transition-all duration-1000 ease-in-out`}>
            <div className='font-noto flex flex-col space-y-3 font-bold absolute top-[15%]'>
                <div className='text-6xl'>
                    Loading
                </div>
                <div className='text-gray-300 text-sm'>
                    This may take a minute...
                </div>
            </div>
          <div className="mb-4 animate-bounce">
            <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
          </div>
          <p className="text-lg font-semibold">{tips[tipIndex]}</p>
        </div>
      );
}



export default Loading