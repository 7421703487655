import React from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";

export default function Payment() {

  return (
    <>
      <div className="card">
        <img src="/img/camera.jpg" alt="Camera" />
        <p>New Camera</p>
        <p className="price">$100.00</p>
        <button id="checkout-button" >Checkout</button>
      </div>
    </>
  )
}
