import React, { useContext, useEffect } from 'react';
import { UserContext } from '../hooks/UserContext.js'; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";

export default function Verify() {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate()
    useEffect(() => {
        if (currentUser && currentUser.emailVerified){
            navigate("/dashboard")
        }
    })
    return (
        <>
        <h1> Please verify your email to access dashboard</h1>
        </>
    )
}