// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail} from "firebase/auth";
import { auth, db } from '../firebase/auth.js';
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(true); // Add a loading state
    const provider = new GoogleAuthProvider();
    const [userQualities, setUserQualities] = useState(null)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("use effect usercontext")
            if (user) {
                setCurrentUser(user);
                pullNameFromDB(user.uid);
            } else {
                setCurrentUser(null);
                setUserName("");  // Clear userName when the user logs out
            }
            setLoading(false); // Set loading to false after checking the user status
        });

        return () => {
            unsubscribe();
        };
    }, []);
    
    // ... rest of your existing code ...
    async function pullNameFromDB(userId) {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUserName(docSnap.data().name); // Update userName based on the database
            setUserQualities(docSnap.data())
        } else {
            console.log("No such document!");
            setUserName(""); // Clear userName if the document doesn't exist
        }
    }

    const resetUserPassword = async (email) => {
        try {
          await sendPasswordResetEmail(auth, email);
          console.log('Password reset email sent successfully!');
          // Here, you can inform the user that the email has been sent or update your UI accordingly
        } catch (error) {
          // Error occurred
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Error in sending password reset email: ${errorCode}`, errorMessage);
          // Handle errors here, such as informing the user of the failure
        }
    };

    const handleDB = async (uid, email, name) => {
        if (!uid) {
            console.error("No UID provided for Firestore document");
            return;
        }
        if (!currentUser?.tier) {
        let registrationForm = {
            name: name,
            email: email,
            tier: "free",
            initialize: false,
            budget: false, 
            counter: 0
        };
        try {
            await setDoc(doc(db, "users", uid), registrationForm);
            console.log("User data stored with UID:", uid);
        } catch (e) {
            console.error("Error adding document with UID: ", e);
        }
    }
    };

    const googleAuth = async () => {
        try {
            const userCredential = await signInWithPopup(auth, provider);
            const { uid, email, displayName } = userCredential.user;
            const name = displayName.split(" ")[0];
            if (uid) {
                await handleDB(uid, email, name);
                console.log("Redirecting to home page...");
                window.location.href = '/';  // Redirect on successful Google login
            } else {
                console.error("Failed to retrieve UID");
                alert("Authentication failed. Please try again.");
            }
        } catch (error) {
            console.error("Authentication error: ", error.message);
            alert("Authentication failed. Please check your network and try again.");
        }
    };

    const logout = async () => {
        await signOut(auth);
        setCurrentUser(null);
    };

    const updatePremium = async () => {
        const userDocRef = doc(db, 'users', currentUser.uid);
        try {
            await updateDoc(userDocRef, {
                initialize: true
            });
            console.log("Field updated successfully");
        } catch (error) {
            console.error("Error updating field: ", error);
        }
    
    }

    const changeUserValue = async (uid, fieldName, newValue) => {
        if (!uid) {
            console.error("No UID provided for Firestore document");
            return;
        }
        // Create a reference to the document
        const userRef = doc(db, "users", uid);
    
        try {
            // Update the specified field
            await updateDoc(userRef, {
                [fieldName]: newValue
            });
            console.log(`Field ${fieldName} updated for UID:`, uid);
            setUserQualities(prevState => ({
                ...prevState,
                [fieldName]: newValue
            }));
        } catch (e) {
            console.error(`Error updating field ${fieldName} for UID: `, e);
        }
    }

    const checkAmount = async () => {
 // Create a reference to the document
        const userRef = doc(db, "users", currentUser?.uid);
        try {
            // Get the current value of the 'counter' field
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                const currentCounter = docSnap.data().counter || 0;
                // Increment the 'counter' field
                changeUserValue(currentUser?.uid, "counter", currentCounter+1)
            } else {
                console.error("No such document!");
            }
        } catch (e) {
            console.error(`Error updating counter field for UID: `, e);
        }
    }

    return (
        <UserContext.Provider value={{ currentUser, userName, resetUserPassword, setUserName, loading, logout, googleAuth, userQualities, updatePremium, changeUserValue, checkAmount }}>
            {!loading && children}
        </UserContext.Provider>
    );
};

