import { useState, useEffect } from 'react';
import {auth, db} from "../firebase/auth.js"
import useCollection from './useCollection.js';

const useGPT = (currentUser, budgeting) => {
    const [typing, setTyping] = useState(false);
    const [inputMessage, setInputMessage] = useState('');
    const { addToSession } = useCollection(currentUser);
    // Inital messages set for user to see upon redner
    let initial_message = budgeting ? "Hey there! Let me help you set up a budget. What are some goals you have for your future financial standings?" : "Hi, I'm Fin! Ask me anything about finance, stocks, investments, etc."
    const [messages, setMessages] = useState([
        {
            message: initial_message,
            sender: "Finbot"
        }
    ]);

    async function processingMessageToFinbot(chatMessages, budget) {
        let apiMessages = chatMessages.map((messageObject) => ({
            role: messageObject.sender === "Finbot" ? "assistant" : "user",
            content: messageObject.message
        }));
        let content = "You are stock predictor and financial advisor. Give stock predictions and financial advice depending on the circumstance. If the users request is not directly related to finance or cryptocurrency, politely reject it."
        if (budget) {
            content = 
            `
            Start by receiving an input in the form of an array of expenses, where each expense is presented as an object with properties such as 'name', 'price', and 'type', distinguishing between 'fixed' and 'variable' expenses. Your primary task is to analyze these expenses to identify potential reductions, focusing especially on variable expenses such as dining out or shopping. Offer strategic advice on reducing these variable costs more significantly than fixed expenses like rent or utilities.

            Engage interactively with the user, guiding them through an evaluation of each expense category and exploring possible reductions. Discuss the financial implications of these cuts and propose specific amounts or percentages by which expenses can be reduced. Once the user has reviewed and agreed to certain budget adjustments, prompt them to use the code word "SELECT BUDGET" to finalize their decisions.

            Upon hearing "SELECT BUDGET" from the user, format the final revised list of expenses as a JSON array of objects, where each object represents one expense with a key being the name of the expense and the value being the new_price. For example, the output should look like this: [{"Dining Out": 90}, {"Entertainment": 127.5}, {"Clothing": 70}]. This JSON format will clearly display each updated expense along with its new price, making it easy for the user to see and implement their new budget. Ensure that the interaction remains focused on the user’s needs, confirming changes with them before providing the final JSON output, tailored to their specific financial planning requirements. Ensure a friendly nature when walking the user through potential cuts in their expenses. Non-budget related requests will be respectfully declined
            \n\n 
            Expenses:\n
                ${JSON.stringify(budget)}
            `
        }
        const systemMessage = {
            role: "system",
            content: content
        };
        
        const latestMessage = chatMessages[chatMessages.length - 1].message;

        const stockSymbol = await processMessageForCompanyName(latestMessage);

        if (stockSymbol) {
             // Fetch stock data using Alpha Vantage API
            const stockData = await fetchStockData(stockSymbol);

            if (stockData) {
                try {
                    
                    const stockDataSummary = formatMarketStackDataForGPT(stockData);

                    trainModelWithFetchedData(stockData)

                    const stockPrediction = await fetchAndPredictStockPrice(stockData);

                    const gptPrompt = createGPTPrompt(latestMessage, stockDataSummary);

                    const gptResponse = await sendPromptToGPT(gptPrompt);
        
                    // Format and send the stock data as a message from Finbot
                    setMessages(prevMessages => [
                        ...prevMessages, 
                        {
                            message: gptResponse,
                            sender: "Finbot"
                        }
                    ]);
                } catch (error) {
                    console.error("Error fetching stock data:", error);
                }
            } else {
                // Process the message through GPT as usual
                try {
                    const response = await fetch("https://api.openai.com/v1/chat/completions", {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ "model": "gpt-3.5-turbo", "messages": [systemMessage, ...apiMessages] })
                    });
        
                    const data = await response.json();
                    setMessages(prevMessages => [
                        ...prevMessages, 
                        {
                            message: data.choices[0].message.content,
                            sender: "Finbot"
                        }
                    ]);
                } catch (error) {
                    console.error("Error processing message:", error);
                }
            }
        } else {
            // Process the message through GPT as usual
            try {
                const response = await fetch("https://api.openai.com/v1/chat/completions", {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ "model": "gpt-3.5-turbo", "messages": [systemMessage, ...apiMessages] })
                });
    
                const data = await response.json();
                setMessages(prevMessages => [
                    ...prevMessages, 
                    {
                        message: data.choices[0].message.content,
                        sender: "Finbot"
                    }
                ]);
            } catch (error) {
                console.error("Error processing message:", error);
            }
        }
    
        // Toggle off the typing indicator
        setTyping(false);
    }

    /*async function getStockSymbolForCompanyName(companyName) {
        const url = `https://api.marketstack.com/v1/eod?access_key=${process.env.REACT_APP_MARKETSTACK_API_KEY}&symbols=${companyName}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
    
            if (data.bestMatches && data.bestMatches.length > 0) {
                // Assuming the first match is the most relevant
                const bestMatch = data.bestMatches[0];
                return bestMatch['1. symbol']; // Extract the stock symbol
            } else {
                console.log("No matches found for company name:", companyName);
                return null;
            }
        } catch (error) {
            console.error("Error fetching stock symbol for company name:", companyName, error);
            return null;
        }
    }*/
    
    function createGPTPromptForCompanyName(userMessage) {
        return `Determine if the following message mentions a specific company, ETF, or stock ticker symbol, and if so, extract the company name or ETF ticker symbol and respond with just the one word stock ticker symbol for the company name or ETF: "${userMessage}"`;
    }
    
    async function processMessageForCompanyName(userMessage) {
        const gptPrompt = createGPTPromptForCompanyName(userMessage);
    
        // Send the prompt to GPT (use your existing GPT API setup)
        const gptResponse = await sendPromptToGPT(gptPrompt);
        console.log(gptResponse)
    
        // Process the GPT response to extract the company name
        // The response handling might depend on how GPT structures its reply
        //const companyName = extractCompanyNameFromGPTResponse(gptResponse);
    
        return gptResponse;
    }

    async function fetchStockData(symbol) {
        const url = `https://api.marketstack.com/v1/eod?access_key=${process.env.REACT_APP_MARKETSTACK_API_KEY}&symbols=${symbol}&limit=1000`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.data && data.data.length > 0) {
                console.log("Valid stock symbol:", symbol);
                return data.data;
            } else {
                console.log("Invalid or unsupported stock symbol:", symbol);
                return null;
            }
        } catch (error) {
            console.error("Error fetching data from MarketStack:", error);
            return null;
        }
    }

    const trainModelWithFetchedData = async (historicalData) => {
        try {
            const response = await fetch('http://127.0.0.1:5000/train-model', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ historicalData }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            console.log(result.message); // Log the response message
        } catch (error) {
            console.error("Error in sending data for model training", error);
        }
    }
    

    const fetchAndPredictStockPrice = async (stockData) => {
        try {
            // Send this data to Flask for prediction
            const response = await fetch('http://127.0.0.1:5000/predict-with-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ stockData }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            console.log(result.predicted_price); // Display the predicted price
        } catch (error) {
            console.error("Error in fetching and predicting stock price", error);
        }
    }

    function formatMarketStackDataForGPT(stockData) {
        if (!stockData || stockData.length === 0) {
            return "Stock data is not available.";
        }
        
        // Extract the latest data point
        const latestData = stockData[0];
    
        const openPrice = latestData.open;
        const highPrice = latestData.high;
        const lowPrice = latestData.low;
        const closePrice = latestData.close;
        const volume = latestData.volume;
        const split_factor = latestData.split_factor;
        const dividend = latestData.dividend;
    
        // Percentage change calculation (if needed)
        const percentChange = ((closePrice - openPrice) / openPrice * 100).toFixed(2);
    
        // Format the message with all the relevant details
        return `The latest data is:
            - Open Price: $${openPrice}
            - High Price: $${highPrice}
            - Low Price: $${lowPrice}
            - Close Price: $${closePrice}
            - Volume: ${volume}
            - Percentage Change: ${percentChange}%
            - Split Factor: ${split_factor}
            - Dividend: ${dividend}`;
    } 
    
    function createGPTPrompt(userMessage, stockDataSummary) {
        // Combine the user's question with the stock data summary
        return `${userMessage}. After you answer the user's quesion please provide insight and potential investment advice given the following real-time information on ${stockDataSummary}`;
    }

    async function sendPromptToGPT(prompt) {
        // Send the prompt to the GPT API and return the response
        const response = await fetch("https://api.openai.com/v1/chat/completions", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ "model": "gpt-3.5-turbo", "messages": [{ "role": "system", "content": prompt }] })
        });
        const data = await response.json();
        return data.choices[0].message.content;
    }


    return ({messages, setMessages, typing, setTyping, inputMessage, setInputMessage, processingMessageToFinbot, sendPromptToGPT})
}

export default useGPT