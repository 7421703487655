import { useState, useEffect, useRef } from 'react';
import {auth, db} from "../firebase/auth.js"
import Hamburger from '../components/hamburger.js';
import { addDoc, getDocs, collection, serverTimestamp, onSnapshot, doc, updateDoc, arrayUnion, query, where, deleteDoc, orderBy } from 'firebase/firestore';

const useCollection = (initialData) => {
    const [user, setCollection] = useState(initialData);
    const [sessions, setSessions] = useState([]);
    const [pastSessions, setPastSessions] = useState()
    const lastFetchedTimestamp = useRef(null);

    useEffect(() => {
        if (user && user.uid) {
            console.log("use effect collection");

            let queryRef = collection(db, 'users', user.uid, 'session');
            if (lastFetchedTimestamp.current) {
                queryRef = query(queryRef, where('timestamp', '>', lastFetchedTimestamp.current));
            }

            const unsubscribe = onSnapshot(queryRef, (snapshot) => {
                const updatedSessions = [];
                snapshot.docs.forEach(doc => {
                    const data = doc.data();
                    updatedSessions.push({ id: doc.id, ...data });

                    if (!lastFetchedTimestamp.current || data.timestamp > lastFetchedTimestamp.current) {
                        lastFetchedTimestamp.current = data.timestamp;
                    }
                });

                setSessions(prevSessions => [...prevSessions, ...updatedSessions]);
            });

            return () => unsubscribe();
        }
    }, [user]);

    const addToSession = async (newText, id) => {
        const lastResponse = newText[newText.length - 1]
        if (id) {
        const sessionRef = doc(db, 'users', user.uid, 'session', id);
        try {
            await updateDoc(sessionRef, {
                texts: arrayUnion(lastResponse)
            });
        } catch (error) {
            console.error("Error updating session: ", error);
        }
        }
    };

    const createSession = async (items) => {
        const sessionObject = {
            texts: items, // Array of texts
            timestamp: serverTimestamp()
        };
        try {
            const docRef = await addDoc(collection(db, 'users', user.uid, 'session'), sessionObject);
            console.log(docRef.id, "DocRef")
            console.log("Session added successfully");
            return docRef.id
        } catch (error) {
            console.error("Error adding session: ", error);
        }
    }

    const pullSessions = async () => {
        const sessionsColRef = collection(db, 'users', user.uid, 'session');
        const queryOrderedByTimestamp = query(sessionsColRef, orderBy('timestamp', 'desc')); // Ordered by timestamp in descending order
        const querySnapshot = await getDocs(queryOrderedByTimestamp);
    
        let sessions = querySnapshot.docs.map(doc => ({
            id: doc.id, 
            ...doc.data()
        }));
        let allSessions = sessions
        // Check if there are more than 10 sessions
        if (sessions.length > 10) {
            // Identify the sessions to delete (all sessions after the first 10)
            const sessionsToDelete = allSessions.slice(10);
    
            // Delete these sessions from Firestore
            for (const session of sessionsToDelete) {
                const sessionRef = doc(db, 'users', user.uid, 'session', session.id);
                await deleteDoc(sessionRef); // Make sure to import deleteDoc from 'firebase/firestore'
            }
    
            // Update the sessions array to the latest 10
            sessions = allSessions.slice(0, 10);
        }
    
        // Update the local state
        setPastSessions(sessions);
    };

    
    return { user, sessions, addToSession, pastSessions, pullSessions, createSession };
};

export default useCollection;
