import React, { useState, useEffect, useRef } from 'react';

function SearchInput({ data, className, handleSubmit, searchInput }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [input, setInput] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const wrapperRef = useRef(null); // Ref for the wrapper to help detect outside clicks

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setFilteredResults([]); // Clear the results if clicked outside
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    // Only auto-fill if searchInput.item is provided and not manually editing
    if (searchInput && searchInput?.item) {
      setInput(searchInput.item)
      handleSelect(searchInput.item)
    }
  }, [searchInput]);


  const handleSearch = (event) => {
    const value = event;
    setSearchTerm(value);
    if (value.length) {
      const filteredData = data.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredResults(filteredData);
    } else {
      setFilteredResults([]);
    }
  };

  const handleSelect = (item) => {
    console.log("Selected",)
    handleSubmit(item);
    setFilteredResults([])
    setSearchTerm(item)
  };

  return (
    <div ref={wrapperRef} >
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => {handleSearch(e.target.value); setInput(e.target.value)}}
        className={className}
        placeholder="Search..."
      />
      {filteredResults.length > 0 && (
        <ul className="list-none absolute text-sm font-semibold bg-white text-gray-900 shadow-sm ring-1 ring-gray-300 w-[15%] rounded-lg m-0 mt-2 p-0 max-h-32 overflow-y-auto">
          {filteredResults.map((item, index) => (
            <li key={index} className="p-2 hover:bg-gray-100 cursor-pointer" onClick={(e) => handleSelect(item, e)}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchInput;
