import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { auth, db, storage } from '../firebase/auth.js';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { getAuth, updateProfile } from 'firebase/auth';
import { doc, updateDoc } from "firebase/firestore";
import 'firebase/storage';
import { useCheckout } from '../components/checkoutButton.js';
import { UserContext } from '../hooks/UserContext.js';
import GenericPFP from "../images/profilepicgeneric.webp"
import LoadingBar from '../components/loadingBar.js';

export default function AccountPage() {
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const { logout, userQualities, currentUser, changeUserValue, resetUserPassword } = useContext(UserContext);
    const { sendToCustomerPortal } = useCheckout();
    const [userProfilePic, setUserProfilePic] = useState(
        userQualities?.hasCustomProfilePic ? userQualities.photoURL : (currentUser?.photoURL || GenericPFP)
      );
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(userQualities?.name || '');

    const toggleEditing = () => setIsEditing(!isEditing);

    const handleNameChange = (e) => {
        setNewName(e.target.value);
    };

    const handleNameUpdate = async () => {
        if (!newName.trim()) return; // Prevent updating to an empty name
    
        // Assuming 'user' is your authenticated user object
        const user = auth.currentUser;
        if (!user) {
            console.error("No user signed in.");
            return;
        }
    
        await changeUserValue(currentUser.uid, 'name', newName)
        setIsEditing(false);
    };

    const handlePasswordUpdate = async () => {
        // Assuming 'user' is your authenticated user object
        const user = auth.currentUser;
        if (!user) {
            console.error("No user signed in.");
            return;
        }
    
        await resetUserPassword(currentUser?.email)
    };

    useEffect(() => {
        // This assumes that 'userQualities' includes 'hasCustomProfilePic' and 'photoURL'
        if (userQualities?.hasCustomProfilePic) {
            setUserProfilePic(userQualities.photoURL);
        } else {
            // Fallback to Google profile picture or a generic one
            setUserProfilePic(currentUser?.photoURL || GenericPFP);
        }
    }, [userQualities, currentUser]);

    const handleOpenCustomerPortal = () => {
        setLoading(true)
        sendToCustomerPortal();
    };

    useEffect(() => {
        if (userQualities) {
          console.log("user qualities changed", userQualities)
          // Perform actions that depend on userQualities
        }
      }, [userQualities]); 
    
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
        if (!allowedTypes.includes(file.type)) {
            alert("Only PNG, JPG, and WebP files are allowed.");
            return;
        }
    
        const user = getAuth().currentUser; // Get the currently signed-in user
        if (!user) {
            console.error("No user signed in.");
            return;
        }

        const currentPhotoPath = userQualities?.photoPath;

        if (currentPhotoPath) {
            // Delete the current photo if it exists
            const currentFileRef = ref(storage, currentPhotoPath);
            try {
                await deleteObject(currentFileRef); // delete the current file
                console.log("Deleted old profile picture.");
            } catch (error) {
                console.error("Error deleting old profile picture: ", error);
                // Handle the error of deletion (maybe the file was already deleted or the path is wrong)
            }
        }
    
        const fileRef = ref(storage, `profilePictures/${user.uid}/${file.name}`);
        try {
            await uploadBytes(fileRef, file);
            const photoURL = await getDownloadURL(fileRef);
        
            // Update Firebase Auth profile
            await updateProfile(user, { photoURL });
    
            // Update Firestore document for the user
            const userDocRef = doc(db, "users", user.uid); // Adjust "users" to your collection path
            await updateDoc(userDocRef, {
                photoURL: photoURL,
                hasCustomProfilePic: true // Setting or updating the flag
            });
    
            // Update local state to immediately reflect the new profile picture
            setUserProfilePic(photoURL);
    
            console.log("Profile picture updated.");
        } catch (error) {
            console.error("Error uploading image: ", error);
        }
    };

    const routeHome = () => {
        navigate("/")
    }

    const handleLogoutAndRedirect = async () => {
        if(logout) {
            await logout(); // Assuming logout is a function that initiates the logout process
        }
        if(routeHome) {
            routeHome(); // Assuming routeHome is a function that redirects to the home page
        }
    };
    
    
    return (
        <>
        <div className='rounded-lg shadow-lg bg-white dark:bg-[#1F2937] transition duration-500 w-full relative h-[98%]'>
            <div className='absolute -top-16 left-[20%] w-3/4 '>
                <LoadingBar isLoading={loading}/>
            </div>
            <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-1/3 h-full my-auto relative font-noto'>
                    <div className='flex flex-col justify-center'>
                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="relative group mx-auto ">
                                <img src={userProfilePic} alt="Profile" className="group-hover:opacity-50 group-hover:scale-105 duration-500 cursor-pointer rounded-full h-56 w-56 object-cover p-5" />
                                <input type="file" onChange={handleFileChange} className='hidden' id="fileInput" accept=".png, .jpg, .jpeg, .webp" />
                                <label htmlFor="fileInput" className="absolute inset-0 flex justify-center items-center cursor-pointer dark:text-white text-black opacity-0 group-hover:opacity-100 duration-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                </label>
                            </div>
                        </div>
                        <div className='flex flex-row'>
                            {isEditing ? (
                                <input 
                                    type="text"
                                    value={newName}
                                    onChange={handleNameChange}
                                    onBlur={handleNameUpdate} // Optional: update name on input blur
                                    className="font-bold text-lg mt-2 ml-3 text-center items-center text-black transition duration-500 w-full"
                                    autoFocus
                                />
                            ) : (
                                <div className='items-center text-center text-black dark:text-white transition duration-500 text-3xl font-bold w-full font-comfortaa'>
                                    {userQualities && userQualities.name}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div onClick={handleLogoutAndRedirect} className='bottom-4 right-4 absolute justify-self-end rounded-lg border-2 bg-red-600 dark:bg-red-700 border-red-600 dark:border-red-700 text-white hover:text-white px-5 py-1 hover:scale-110 hover:bg-red-600 dark:hover:bg-red-700 hover:border-red-600 dark:hover:border-red-700 duration-500 cursor-pointer text-center'>Logout</div>
                <div className='flex flex-col w-full md:w-2/3 font-noto'>
                    <div className='md:text-3xl lg:text-4xl mt-5 text-center md:text-start font-bold dark:text-white w-full transition duration-500'>Personal Information</div>
                    <div className='opacity-50 dark:text-white px-2 md:px-0 text-center md:text-start transition mt-5 duration-500 text-sm'>Manage your personal information, edit your profile, and manage your subscription</div>
                    <div className='grid grid-cols-1 md:grid-cols-2 h-[70%] mt-10 gap-5 w-[100%] px-2 md:px-0 md:pr-5 mb-10 md:mb-0 text-white'>
                        <div className='bg-finblue dark:bg-inkfin transition duration-500 rounded-lg shadow-lg'>
                            <div className='flex flex-row'>
                                <div className='flex flex-col w-1/2'>
                                    <div className='text-2xl font-bold font-noto mt-10 ml-10'>Name</div>
                                    <div className='font-noto mt-5 ml-10'>
                                        <div 
                                            onClick={() => {
                                                if (isEditing) {
                                                    handleNameUpdate(); // Save the changes
                                                } else {
                                                    toggleEditing(); // Just enter edit mode
                                                }
                                            }}
                                            className="mb-5 cursor-pointer rounded-lg border-2 border-white text-black py-1 hover:scale-110 bg-white duration-500 text-center text-xs"
                                        >
                                            {isEditing ? "Save Name" : "Change Name"}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col w-1/2 items-end'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-10 mt-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='bg-finblue dark:bg-inkfin transition duration-500 rounded-lg shadow-lg'>
                            <div className='flex flex-row'>
                                <div className='flex flex-col w-1/2'>
                                    <div className='text-2xl font-bold font-noto mt-10 ml-10'>Email</div>
                                    <div className='font-noto mt-5 ml-10 opacity-50 mb-5 md:mb-0'>
                                        <em>{userQualities && userQualities.email}</em>
                                    </div>
                                </div>
                                <div className='flex flex-col w-1/2 items-end'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-10 mt-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='bg-finblue dark:bg-inkfin transition duration-500 rounded-lg shadow-lg'>
                            <div className='flex flex-row'>
                                <div className='flex flex-col w-1/2'>
                                    <div className='text-2xl font-bold font-noto mt-10 ml-10'>Manage Password</div>
                                    <div className='font-noto mt-5 ml-10'>
                                        <div onClick={handlePasswordUpdate} className="mb-5 cursor-pointer rounded-lg border-2 border-white text-black py-1 hover:scale-110 bg-white duration-500 text-center text-xs">
                                            Change Password
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col w-1/2 items-end'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-10 mt-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='bg-finblue dark:bg-inkfin transition duration-500 rounded-lg shadow-lg'>
                            <div className='flex flex-row'>
                                <div className='flex flex-col w-1/2'>
                                    <div className='text-2xl font-bold font-noto mt-10 ml-10'>Manage Plan</div>
                                    <div className='font-noto mt-5 ml-10'>
                                        <div onClick={handleOpenCustomerPortal} className="mb-5 cursor-pointer rounded-lg border-2 border-white text-black py-1 hover:scale-110 bg-white duration-500 text-center text-xs">
                                            Manage Subscription
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col w-1/2 items-end'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-10 mt-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                    </svg>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}