import React from 'react'
import { useSpring, animated } from 'react-spring';

export default function LoadingBar({isLoading}) {
    const props = useSpring({
        reset: true,
        from: { width: '0%' },
        to: { width: '100%' },
        loop: true,                // Loop the animation
        config: { duration: 1500 } // Duration of one animation cycle
      });
    
        return (
            <>
                {isLoading &&
                    (<div style={{ width: '75%', backgroundColor: '#ddd', height: '5px', borderRadius: '5px' }}>
                    <animated.div style={{
                    ...props,
                    height: '5px',
                    backgroundColor: '#8c8c8c',
                    borderRadius: '5px'
                    }} />
                </div>)
                }
            </>
          
        );
      }
