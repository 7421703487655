import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

function ErrorMessage({handleSubmit, message}) {
  const [progress, setProgress] = useState(100);
  const [timerId, setTimerId] = useState(null); // State to keep track of the timer ID
  const [isVisible, setIsVisible] = useState(false); // Controls the visibility for animation
  const [shouldRender, setShouldRender] = useState(true);
  console.log(progress)
  // React Spring animation for the loading bar
  const loadingBarSpring = useSpring({
    width: progress + '%',
    backgroundColor: 'white',
    position: 'absolute',
    height: '6px',
    bottom: '0',
    left: '0',
  });

  // React Spring animation for sliding in and out
  const slideAnimation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateX(0)' : 'translateX(100%)',
    config: { tension: 150, friction: 20 },
  });

  useEffect(() => {
    // Slide in when the component mounts
    if (message) {
        setIsVisible(true);

    // Define the function that updates the progress
    const updateProgress = () => {
      setProgress((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          // Stop the timer when progress reaches 0
          setIsVisible(false); // Start slide out when the timer ends
          handleSubmit(null)
          return prev;
        }
      });
    };

    // Start the timer
    const id = setInterval(updateProgress, 40); // Adjust for timer speed
    setTimerId(id);

    return () => clearInterval(id);
    }
  }, [message]);

  // Pause the timer
  const handleMouseEnter = () => {
    clearInterval(timerId);
  };

  // Resume the timer
  const handleMouseLeave = () => {
    const id = setInterval(() => {
      setProgress((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(id); // Make sure to clear this interval as well
          setIsVisible(false); // Start slide out when the timer ends
          return prev;
        }
      });
    }, 40); // Adjust for timer speed
    setTimerId(id);
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <animated.div style={slideAnimation} className="absolute right-10 top-10 z-30">
      <div 
        className="relative w-48 h-24 rounded-lg bg-red-400 flex justify-center items-center shadow-xl"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
            <animated.div style={loadingBarSpring} className={"flex rounded-lg"}/>
            <div className='absolute right-1 top-1 z-40' onClick={() => {setIsVisible(false); setProgress(100); clearInterval()}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div className='font-comfortaa text-center z-40'>
                {message}
            </div>
        </div>
    </animated.div>
  );
}

export default ErrorMessage;
