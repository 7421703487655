import React, { useState, useEffect, useContext } from 'react';
import { auth, db } from '../firebase/auth.js';
import { addDoc, collection, serverTimestamp, getDoc, getDocs, setDoc, doc, updateDoc, arrayUnion, ref, set} from 'firebase/firestore';
import { UserContext } from './UserContext.js';
import { forEach } from 'lodash';

const useBudgetData = () => {
    const [backgroundBudget, setBackgroundBudget] = useState([]);
    const [monthlyExpenses, setMonthlyExpenses] = useState('');
    const [projected_data, setProjected_Data] = useState(null)
    const { currentUser, changeUserValue, userQualities } = useContext(UserContext);
    const [budgetData, setBudgetData] = useState({backgroundBudget : backgroundBudget, monthlyExpenses : monthlyExpenses})
    const [loading, setLoading] = useState(true); // Add a loading state
    const [toggle, setToggle] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const budget_data = await fetchBudgetData();
            const proj_data = await fetchProjectedBudget();
            setBudgetData(budget_data)
            setProjected_Data(proj_data)
            setLoading(false);
        };
        fetchData();
    }, [toggle]);

    
    const fetchBudgetData = async () => {
        // Assuming 'budget-expenses' is the correct collection path
        if (currentUser?.uid){
        const collectionRef = collection(db, 'users', currentUser.uid, 'budget-data');
    
        try {
            const querySnapshot = await getDocs(collectionRef);
            const budgetData = querySnapshot.docs.map(doc => doc.data()); // Convert each doc to data
            // Assuming the structure is consistent and you're interested in the first document
            if (budgetData.length > 0)  {
                return {backgroundBudget : budgetData[0]?.backgroundData?.current, monthlyExpenses : budgetData[0]?.graph?.MonthlyExpenses}
            }
            else {
                console.log("No documents found.");
                return {};
            }
        } catch (error) {
            console.error("Error fetching budget data:", error);
            return {};
        }
        }
    };

    const fetchProjectedBudget = async () => {
        if (currentUser?.uid) {
        const docRef = doc(db, `users/${currentUser.uid}/budget-data/projected-budget`);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              console.log("Projected budget data:", docSnap.data());
              setProjected_Data(docSnap.data())
              return docSnap.data()
            } else {
              console.log("No such document!");
              return null; // Indicates no data was found
            }
          } catch (error) {
            console.error("Error fetching document: ", error);
            return null; // Handle the error or return null
          }
        }
    }

    const createProjectedBudget = async (reformattedExpenses) => {
        try {
            const docRef = doc(db, `users/${currentUser?.uid}/budget-data/projected-budget`);
            // Attempt to fetch the existing document
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists() || (docSnap.exists() && Object.keys(docSnap.data()).length === 0)) {
              // Document does not exist or is empty, so add the array
              await setDoc(docRef, { monthlyExpenses: reformattedExpenses.MonthlyExpenses }, { merge: true });
             // User value needs to be changed
              console.log("New document created with items! And updated budget value");
            } else {
              console.log("Document already exists and is not empty.");
            }
          } catch (error) {
            console.error("Error updating document: ", error);
          }
    }

    const addBudgetDataForUser = async (items, reformattedExpenses) => {
        const sessionObject = {
            backgroundData: items[0].BackgroundInformation, // Nested array of texts
            graph: reformattedExpenses,
            timestamp: serverTimestamp()
        };
        
        const fixedDocumentId = "data-expenses"; // Define your fixed ID here

        try {
            // Specify the document path with your fixed ID
            const docRef = doc(db, 'users', currentUser.uid, 'budget-data', fixedDocumentId);
            
            // Set the document with your data object
            await setDoc(docRef, sessionObject);
            console.log(`${fixedDocumentId} - Document created successfully`);
            await createProjectedBudget(reformattedExpenses)
            setToggle(!toggle)
            // Your additional logic
            changeUserValue(currentUser.uid, "initialize", true);
            
            // Since you're using a fixed ID, you already know it, but you can still use it as needed
            return fixedDocumentId;
        } catch (error) {
            console.error("Error adding session: ", error);
        }
      };

    const createData = async (items) => {
        const monthlyExpenses = JSON.stringify(items[1])
        // Crafting the prompt with instructions and the data to reformat
        // 
        const instructions = "Refine your 'MonthlyExpenses' JSON data by enhancing each entry's categorization without removing existing fields. Start by appending an 'item' field to retain the original category name. Assign a broad 'category' from predefined options such as 'Housing', 'Utilities', 'Daily Living', or 'Transportation', creating new categories as needed. Further refine with a 'detailed_category' for specific distinctions, like 'Electricity' or 'Internet' under 'Utilities'. Introduce a 'type' field to classify expenses as 'Fixed' or 'Variable', indicating consistent payments or fluctuating costs. Ensure modifications include adding 'item', 'category', 'detailed_category', and 'type' fields alongside existing data, filling any blanks appropriately, to provide a comprehensive view of each expense in JSON format."
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                { "model": "gpt-3.5-turbo",    
                    messages: [
                {
                    role: "system",
                    content: instructions, // Your instructions variable
                },
                {
                    role: "user",
                    content: `Data:\n${monthlyExpenses}`, // The data to be transformed
                },
                ],})
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            const reformattedExpenses = JSON.parse(result.choices[0].message.content)

            const refExpMap = reformattedExpenses.MonthlyExpenses.reduce((map, obj) => {
                map[obj.item] = {...obj};
                return map;
            }, {});
            
            // Map over data1 and construct new array using data from map
            const data3 = items[1].MonthlyExpenses.map(item => {
                if (refExpMap[item.category]) {
                    return {...refExpMap[item.category], item: item.category};
                }
                return null; // or handle cases where there is no matching item differently
            });
            console.log(data3)
            const x = {MonthlyExpenses : data3}
            setMonthlyExpenses(x.MonthlyExpenses);
            setBackgroundBudget(items[0].BackgroundInformation.current)
            addBudgetDataForUser(items, x)

        } catch (error) {
            console.error('Error:', error);
        }

    }
    
    const addData = async (items, categories, setIsNewExpense, isNewExpese) => {
        const categoriesList = categories.join('\n- ');
        console.log(categoriesList)
        const instructions = `Transform the JSON data to enhance the categorization of each expense. Begin by adding an "item" field to preserve the original category name for each entry. Assign each expense to a general category listed in ${categoriesList} using the "category" field (if you cant assign it to a category, make a new one), and then provide a more specific classification within the "detailed_category" field, taking into account various aspects such as entertainment, health, education, groceries, personal care, and investments. This may involve creating subcategories, for instance, categorizing expenses within 'Utilities' as 'Electricity', 'Water', or 'Internet'. Additionally, incorporate a "type" field to specify whether each expense is 'Fixed' or 'Variable' monthly expense, indicating the nature of the expense as either a consistent finite monthly payment (fixed) or one that fluctuates (variable). Ensure the output is solely the modified object in JSON format, including only the "item", "category", "detailed_category", and "type" fields, without introducing a section for new subcategories or any additional text information. Make sure no field is left blank, if so fill it in accordingly. Only respond with the JSON formatted text and no other words.`
          try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                { "model": "gpt-3.5-turbo",    
                    messages: [
                {
                    role: "system",
                    content: instructions, // Your instructions variable
                },
                {
                    role: "user",
                    content: `Data:\n${JSON.stringify(items)}`, // The data to be transformed
                },
                ],})
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log("Result: ", result.choices[0].message.content)
            let final_content = JSON.parse(result.choices[0].message.content)
            final_content["price"] = items.price
            const docRef = doc(db, `users/${currentUser.uid}/budget-data/data-expenses`);
            // New data to add to monthlyExpenses
            updateDoc(docRef, {
                'graph.MonthlyExpenses': arrayUnion(final_content)
              }).then(() => {
                setIsNewExpense(!isNewExpese)
                console.log('Monthly expense added successfully!');
              }).catch((error) => {
                console.error('Error adding monthly expense:', error);
              });
        } catch (error) {
                    console.error('Error:', error);
                }
        }

    const updateData = async (items, budgetData, dropdownSelect, setIsNewExpense, isNewExpese) => {
        const capitalizeFirstLetter = (str) => {
            return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        };
    
        // Use the utility function on the category
        const formattedCategory = capitalizeFirstLetter(items.category);
        if (dropdownSelect === "Delete") {
            var filteredArr = budgetData.filter(function(obj) {
                // Use the formatted category for comparison
                return obj.item !== formattedCategory;
            });
            budgetData = filteredArr;
        } else {
            console.log(budgetData);
            let expenseFound = false; // Flag to indicate if the expense was found
            for (let x of budgetData) {
                // Use the formatted category for comparison
                if (x.item === formattedCategory) {
                    x.price = items.price; // Directly update the price in the original array
                    expenseFound = true; // Set the flag to true since we found and updated the expense
                    break; // Optional: break the loop if you only expect one match
                }
            }
            if (!expenseFound) {
                console.log("Expense not found for category:", formattedCategory);
            }
        }
    
        const docRef = doc(db, 'users', currentUser.uid, 'budget-data', "data-expenses");
        try {
            await updateDoc(docRef, {
              "graph": {MonthlyExpenses: budgetData} // Directly update the graph field in the document
            });
            console.log('Graph data successfully updated.');
            setIsNewExpense(!isNewExpese);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    const updateCurrent = async (currentEdit, newPrice, budgetData, setIsNewExpense, isNewExpese) => {
        const capitalizeFirstLetter = (str) => {
            return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        };
        // Use the utility function on the category
        const formattedCategory = capitalizeFirstLetter(currentEdit);
        let expenseFound = false; // Flag to indicate if the expense was found
        for (let x of budgetData) {
            // Use the formatted category for comparison
            if (x.item === formattedCategory) {
                x.price = newPrice; // Directly update the price in the original array
                expenseFound = true; // Set the flag to true since we found and updated the expense
                break; // Optional: break the loop if you only expect one match
            }
        }
        if (!expenseFound) {
            console.log("Expense not found for category:", formattedCategory);
        }
        console.log("UPATE PROJ", budgetData)
        const docRef = doc(db, 'users', currentUser.uid, 'budget-data', "data-expenses");
        try {
            await updateDoc(docRef, {
              "graph": {MonthlyExpenses: budgetData} // Directly update the graph field in the document
            });
            console.log('Graph data successfully updated.');
            setIsNewExpense(!isNewExpese);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    const updateProjected = async (currentEdit, newPrice, data, budgetData, proj_expense, setProj_Expense) => {
        const capitalizeFirstLetter = (str) => {
            return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        };
        console.log(projected_data)
        // Use the utility function on the category
        const formattedCategory = capitalizeFirstLetter(currentEdit);
        let expenseFound = false; // Flag to indicate if the expense was found
        for (let x of projected_data.monthlyExpenses) {
            // Use the formatted category for comparison
            if (x.item === formattedCategory) {
                x.price = newPrice; // Directly update the price in the original array
                expenseFound = true; // Set the flag to true since we found and updated the expense
                break; // Optional: break the loop if you only expect one match
            }
        }
        if (!expenseFound) {
            console.log("Expense not found for category:", formattedCategory);
        }

        const docRef = doc(db, 'users', currentUser.uid, 'budget-data', "projected-budget");
        try {
            await updateDoc(docRef, {
              "monthlyExpenses": projected_data.monthlyExpenses // Directly update the graph field in the document
            });
            console.log('Graph data successfully updated. New projected data');
            const x = await fetchProjectedBudget()
            setProj_Expense(x)
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }
    console.log(projected_data, "PD")

    const createBudget = async (newBudget) => {
        // Send to GPT and have reformat
        const content = "Upon receiving any form of text input describing budget adjustments, including both narrative descriptions and itemized lists, parse the content to extract names of expenses and their corresponding new prices. Format these details into a JSON array, where each element is an object consisting of an expense name as the key and the adjusted price as a numerical value. Ensure that the output strictly consists of this JSON formatted data, with no additional text, comments, or explanations. The key objective is to identify and reformat the provided expense information accurately into the specified JSON structure. Such as only [{}, {}]"
        const systemMessage = {
            role: "system",
            content: content
        };
        const x = {role:"user", content:newBudget}
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "model": "gpt-3.5-turbo", "messages": [systemMessage, x] })
            });
            const data = await response.json();
            function extractJSON(text) {
                // Regex to match an array of objects with string keys and numeric values
                const regex = /\[\s*(?:\{\s*".+?"\s*:\s*\d+(\.\d+)?\s*\}\s*,?\s*)+\s*\]/g;            
                // Find matches
                const matches = text.match(regex);
            
                // Parse the first match found to convert it into a JavaScript object
                if (matches && matches.length > 0) {
                    try {
                        const jsonArray = JSON.parse(matches[0]);
                        return jsonArray;
                    } catch (error) {
                        console.error('Failed to parse JSON:', error);
                    }
                } else {
                    console.log('No JSON-like structure found.');
                }
                return null;
            }
            console.log(data.choices[0].message.content)
            const expenses = extractJSON(data.choices[0].message.content)
            expenses.forEach(expense => {
                // Extract key (item name) and value (new price) from each expense object
                const itemName = Object.keys(expense)[0];
                const newPrice = Math.round(expense[itemName]); // Round the price to ensure it is an integer
            
                // Find the matching item in the projected_data array
                const projectedItem = projected_data.monthlyExpenses.find(item => item.item === itemName);
            
                // If a matching item is found, update its price with the new integer value from expenses
                if (projectedItem) {
                    projectedItem.price = newPrice.toString(); // Convert the integer price to a string before updating
                }
            });
            
            // Log the updated projected_data to see the results
            try {
                const docRef = doc(db, `users/${currentUser?.uid}/budget-data/projected-budget`);
                // Attempt to fetch the existing document
                const docSnap = await getDoc(docRef);
                console.log(projected_data, "PJ", expenses, "EXP")
              
                if (!docSnap.exists() || (docSnap.exists() && Object.keys(docSnap.data()).length === 0)) {
                  // Document does not exist or is empty, so add the array
                  await setDoc(docRef, { monthlyExpenses: projected_data.monthlyExpenses }, { merge: true });
                  setProjected_Data(projected_data)
                  // Change Back when deploying await changeUserValue(currentUser.uid, "budget", true)
                  console.log("New document created with items! And updated budget value");
                } else {
                  // Document exists and is not empty, so update the fields
                  const existingData = docSnap.data();
                  const updatedMonthlyExpenses = { ...existingData.monthlyExpenses };
              
                  // Update the price field in the existing data
                  expenses.forEach(expense => {
                    const [key, value] = Object.entries(expense)[0];
                    if (key === 'price') {
                      updatedMonthlyExpenses.price = value;
                    }
                  });
              
                  // Update the document with the modified price field
                  await setDoc(docRef, { monthlyExpenses: updatedMonthlyExpenses }, { merge: true });
                  setProjected_Data(prevData => ({
                    ...prevData,
                    monthlyExpenses: updatedMonthlyExpenses
                  }));
              
                  console.log("Document updated with new price!");
                }
              } catch (error) {
                console.error("Error updating document: ", error);
              }
              
              
        
        } catch (error) {
            console.error("Error processing message:", error);
        }

        
        }

    return {loading, createData, budgetData, fetchBudgetData, updateData, addData, createBudget, fetchProjectedBudget, projected_data, updateCurrent, updateProjected};
    }
export default useBudgetData



// response["detailed_category"] = items.category
// response["item"] = items.category        
// const docRef = doc(db, `users/${currentUser.uid}/budget-data/data-expenses`);

// // New data to add to monthlyExpenses
// updateDoc(docRef, {
//     'graph.MonthlyExpenses': arrayUnion(response)
//   }).then(() => {
//     console.log('Monthly expense added successfully!');
//   }).catch((error) => {
//     console.error('Error adding monthly expense:', error);
//   });