import Navmenu from "./components/navbar.js"
import LandingPage from "./components/landing.js"
import LandingPage2 from "./components/landing2.js"
import Product from "./components/product.js"
import Pricing from "./components/pricing.js"
import Footer from "./components/footer.js"
import Between from "./components/inbetween.js"
import useDarkMode from "./hooks/useDarkMode.js"

export default function Home() {
    return (
        <>
        <div className="dark:bg-inkfin transition duration-500 bg-finblue h-full w-full relative">
            <div className="sticky top-0 w-full z-50">
                <div className="absolute w-full">
                    <Navmenu />
                </div>
            </div>
            <div id='landing' className="flex flex-col h-full w-full relative overflow-hidden">
                <LandingPage2 />
                <div className="flex -mt-20 z-30">
                    <Product /> 
                </div>
                <div id="pricing" className="mb-10">
                    <Pricing />
                </div>
                <Footer />
            </div>
        </div>
        </>
    )
}