import { useNavigate } from "react-router-dom";
import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Squeeze as Hamburger } from 'hamburger-react'
import { AnimatePresence, motion } from "framer-motion";
import { auth, db } from '../firebase/auth.js';
import { doc, getDoc } from "firebase/firestore";
import useDarkMode from "../hooks/useDarkMode.js";
import { UserContext } from '../hooks/UserContext.js';
import LogoText from "../images/StockFin Black Name.png"

export default function NavMenu() {
    // Optimize Firestore DB, reads 10x
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)
    const [theme, setTheme] = useDarkMode();
    const [svg, setSvg] = useState();
    const hamburgerColor = isOpen ? "#FFFFFF" : theme ? "#FFFFFF" : "#000000";
    const { currentUser, userName, setUserName, logout } = useContext(UserContext);

    const hideMenu = () => {
        setOpen(!isOpen)
    }

    const handleTheme = () => {
        var d = theme ? "M9.528 1.718a.75.75 0 0 1 .162.819A8.97 8.97 0 0 0 9 6a9 9 0 0 0 9 9 8.97 8.97 0 0 0 3.463-.69.75.75 0 0 1 .981.98 10.503 10.503 0 0 1-9.694 6.46c-5.799 0-10.5-4.7-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 0 1 .818.162Z" : "M12 2.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM7.5 12a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM18.894 6.166a.75.75 0 0 0-1.06-1.06l-1.591 1.59a.75.75 0 1 0 1.06 1.061l1.591-1.59ZM21.75 12a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5H21a.75.75 0 0 1 .75.75ZM17.834 18.894a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 1 0-1.061 1.06l1.59 1.591ZM12 18a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-1.5 0v-2.25A.75.75 0 0 1 12 18ZM7.758 17.303a.75.75 0 0 0-1.061-1.06l-1.591 1.59a.75.75 0 0 0 1.06 1.061l1.591-1.59ZM6 12a.75.75 0 0 1-.75.75H3a.75.75 0 0 1 0-1.5h2.25A.75.75 0 0 1 6 12ZM6.697 7.757a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 0 0-1.061 1.06l1.59 1.591Z"
        setSvg(d)
        setTheme(!theme)
    }
    
    useEffect(() => {
        var d = theme ? "M12 2.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM7.5 12a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM18.894 6.166a.75.75 0 0 0-1.06-1.06l-1.591 1.59a.75.75 0 1 0 1.06 1.061l1.591-1.59ZM21.75 12a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5H21a.75.75 0 0 1 .75.75ZM17.834 18.894a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 1 0-1.061 1.06l1.59 1.591ZM12 18a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-1.5 0v-2.25A.75.75 0 0 1 12 18ZM7.758 17.303a.75.75 0 0 0-1.061-1.06l-1.591 1.59a.75.75 0 0 0 1.06 1.061l1.591-1.59ZM6 12a.75.75 0 0 1-.75.75H3a.75.75 0 0 1 0-1.5h2.25A.75.75 0 0 1 6 12ZM6.697 7.757a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 0 0-1.061 1.06l1.59 1.591Z" : "M9.528 1.718a.75.75 0 0 1 .162.819A8.97 8.97 0 0 0 9 6a9 9 0 0 0 9 9 8.97 8.97 0 0 0 3.463-.69.75.75 0 0 1 .981.98 10.503 10.503 0 0 1-9.694 6.46c-5.799 0-10.5-4.7-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 0 1 .818.162Z"
        setSvg(d)
        if (currentUser) {
            pullNameFromDB(currentUser.uid);
        } 
    }, [currentUser, setUserName]);

    async function pullNameFromDB(userId) {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setUserName(docSnap.data().name); // Assuming the field containing the name is 'name'
        } else {
            setUserName("");
        }
    }
    
    const routeDashboard = () => {
        navigate("/dashboard")
    }

    const handleRoute = (route) => {
        if (route.altFunction && currentUser) {
            route.altFunction()
        }
        else {
            if (route.id == 4) {
                scrollToSection('products')
            }
            else if (route.id == 3) {
                scrollToSection('pricing')
            }
            navigate(route.link)
        }
    }

    const routes = [
        {id:1, name:"Sign up", altName:"Dashboard", link:"/register", altFunction: routeDashboard},
        {id:2, name:"Login", altName:"Logout", link:"/login", altFunction : logout},
        {id:3, name:"Pricing", link:"/"},
        {id:4, name:"Product", link:"/"}
    ]

    const navList = (route) => {
        if ((route.id === 1 || route.id === 2) && currentUser) {
            return (route.altName)
        }
        return (route.name)
    }

    const scrollToSection = (data) => {
        const productsSection = document.getElementById(data);
        if (productsSection) {
          productsSection.scrollIntoView({ behavior: 'smooth'});
        }
      }

    return (
        <>
            <div className="absolute hidden lg:grid transition duration-500 dark:text-white text-black relative justify-items-stretch">
                <div className="w-full flex opacity-95 justify-between mr-2">
                    <div className="flex top-0 left-0">
                        <div className="w-full px-2 flex place-items-center" key="">
                            <div className="flex h-3/4 w-[10%] border-2 border-black dark:border-white rounded-md">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className = "w-full h-full flex self-center " onClick={handleTheme}>
                                <path fillRule="evenodd" d={svg} clipRule="evenodd" />
                                </svg>
                            </div>
                                <div className="flex flex-col font-comfortaa pl-5 justify-center text-center">
                                    <div>Welcome</div>
                                    <div>{userName}</div>
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-row-reverse place-self-end pr-5">
                        {routes.map(route => (
                            <React.Fragment key={route.id}>
                                <button className="p-4 mt-2 rounded-lg font-comfortaa transform motion-safe:hover:scale-110" onClick={() => handleRoute(route)}>
                                {navList(route)}
                                </button>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>

    
            <div className="lg:hidden flex flex-row bg-white dark:bg-black dark:text-white justify-between opacity-85">
                    <div className="flex">
                        <div className="w-full px-2 flex place-items-center">
                            <button className="flex h-3/4 w-[10%] border-2 border-black rounded-md z-10" onClick={handleTheme}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className = "w-full h-full flex self-center " >
                                <path fillRule="evenodd" d={svg} clipRule="evenodd" />
                                </svg>
                            </button>
                                <div className="flex flex-col font-comfortaa pl-5 justify-center text-center">
                                          
                                    <div>Welcome</div>
                                    <div>{userName}</div>

                                </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="z-40">
                            <Hamburger toggled={isOpen} color={hamburgerColor} toggle={setOpen} size={25} />
                        </div>
                        {/* Overlay Div */}
                        <div className="absolute w-screen h-screen top-0 left-0">
                            <AnimatePresence>
                                {isOpen && (
                                    <>
                                        <button className="absolute bg-white w-1/3 h-screen left opacity-0" onClick={hideMenu}></button>
                                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className="absolute w-2/3 top-0 right-0 h-screen" >
                                            <div className="bg-black flex flex-col justify-center h-full w-full">
                                                {routes.map(route => (
                                                    <div key={route.id} className="w-full h-1/6 flex justify-center text-2xl">
                                                    <motion.div
                                                        key={route.id}
                                                        initial={{ scale: 0, opacity: 0 }}
                                                        animate={{ scale: 1, opacity: 1 }}
                                                        transition={{
                                                        type: "spring",
                                                        stiffness: 260,
                                                        damping: 20,
                                                        }}>
                                                        <button key ={route.id} className="text-white cursor-pointer p-2 rounded-sm border-b-2 "  onClick={() => handleRoute(route)}>
                                                            {navList(route)}
                                                        </button>
                                                    </motion.div>
                                                </div>  
                                                ))}
                                            </div>
                                        </motion.div>     
                                    </>
                                    
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
    </>
);}