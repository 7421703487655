import React, { useState, useEffect, useRef } from 'react';

export default function Dropdown({ data, onSelect, textDisplayed }) {
    const [isOpen, setIsOpen] = useState(false);
    const [item, setItem] = useState(textDisplayed)
    const wrapperRef = useRef(null);
  
    useEffect(() => {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const toggleDropdown = () => setIsOpen(!isOpen);
  
    const handleSelect = (item) => {
      setItem(item)
      onSelect(item); // Call the callback function with the selected item
      setIsOpen(false); // Optionally close the dropdown after selection
    };
    return (
      <div ref={wrapperRef} className="flex w-full relative overflow-visible inline-block text-left">
        <div>
          <button onClick={toggleDropdown} type="button" className=" w-full justify-center flex flex-row gap-1 items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded={isOpen} aria-haspopup="true">
            {item}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${isOpen && "rotate-180"} transition duration-500 pt-1`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>

          </button>
        </div>
        {isOpen && (
          <div className="absolute right-0 z-10 mt-12 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
            <div className="py-1" role="none">
              {data.map((item, index) => (
                <a key={index} href="#" className="text-gray-700 block px-4 hover:bg-gray-200 py-2 text-sm" role="menuitem" tabIndex="-1" onClick={() => handleSelect(item)}>
                  {item}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }