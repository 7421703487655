import { useTransition, animated } from 'react-spring';
import { useRef, useEffect, useState } from 'react';
function ThreeDotMenu({ options, graphOption, changeOption }) {

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const menuRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Only close the menu if it's open. Prevents opening the menu when clicking outside.
        if (isMenuOpen) {
          setIsMenuOpen(false);
        }
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);
  // Define the transition for the menu
   const transitions = useTransition(isMenuOpen, {
    from: { opacity: 0, transform: 'translateY(-10px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-10px)' },
    config: { duration: 200 },
  });

  const handleSelectOption = (option) => {
    console.log("Selection", option)
    changeOption(option);
    // Do not toggle the menu state here if you want it to remain open
  };

  return (
    <div className='flex absolute p-3 left-0 top-0 w-full h-full z-30 flex-row' ref={menuRef}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mt-2 flex cursor-pointer absolute z-30" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
      </svg>
      {transitions((styles, item) => item && 
        <animated.div style={styles} className='absolute flex flex-col pl-4 font-noto justify-center shadow-lg bg-white items-center p-2 rounded-lg'>
          {options.map((option, index) => (
            <div key={index} className="flex w-full px-5 transition duration-500" onClick={() => handleSelectOption(option)}>
              {option === graphOption ? 
                <div className='bg-gray-50 text-gray-400 flex justify-center items-center w-full transition duration-500 disabled py-2'>{option}</div>
                : 
                <div className='py-2 flex justify-center px-5 hover:bg-gray-100 rounded-sm hover:scale-105 items-center w-full transition duration-500 cursor-pointer'>
                  {option}
                </div>
              }
            </div>
          ))}
        </animated.div>
      )}
    </div>
  );
}
export default ThreeDotMenu