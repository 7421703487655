import {useState} from "react"
import {auth} from "../firebase/auth.js"
import {sendPasswordResetEmail} from "firebase/auth"
import Logo from "../images/LoginLogoLight.png"
import LogoDark from "../images/logo_dark.png"
import {useNavigate} from "react-router-dom"
import useDarkMode from "../hooks/useDarkMode.js"

export default function ForgotPass() {
    const [email, setEmail] = useState("")
    const [theme, setTheme] = useDarkMode()
    const navigate = useNavigate()
    const sendPasswordReset = async () => {
        try{
            await sendPasswordResetEmail(auth, email)
            navigate("/login")
        }
        catch (error) {
            console.log(error)
        }

    }
    return (
        <>
        <div className="flex h-screen w-screen relative bg-darkfin dark:bg-inkfin items-center justify-center">
        <div className="absolute top-0 left-0 animate-fade-up flex"><img className="w-0 md:w-1/3 p-2 ml-2" src={LogoDark} alt="StockFin Logo" /></div>
                <div className="animate-fade-up relative z-10 bg-white dark:bg-[#1f2937] rounded-lg shadow-xl lg:w-2/3 w-5/6 h-2/3 border-gray-100">
                    <div className="absolute z-10 bottom-0 inset-x-0">
                        <div className="font-comfortaa text-sm flex flex-row justify-center dark:text-gray-100">
                            <div className="p-2 "><p>Dont have an account? <a href="/register" className="underline">Sign up</a></p></div>
                            <div className="p-2 invisible md:visible">|</div>
                            <div className="p-2 border-l-2 md:border-l-0"><p> Or, back to <a  href="/login" className="underline cursor-pointer">Sign In</a></p></div>
                        </div>
                    </div>
                    <div className="absolute inset-x-0 top-10"><div className="font-comfortaa mx-auto w-2/3 font-bold md:text-3xl text-xl text-center rounded-md dark:text-gray-100">Let's reset your password!</div></div>
                    <div className="flex relative justify-center items-center w-full h-full">
                        <div className=" flex flex-col place-items-center justify-center w-full">
                            <div className="py-5 w-3/4 md:w-2/3 "> <input className="w-full font-comfortaa border-b-2 outline-none bg-transparent dark:text-white" placeholder="Email" onChange={(e) => {setEmail(e.target.value)}}/> </div>
                                <button className="transform motion-safe:hover:scale-110 duration-500 bg-inkfin dark:bg-finblue text-gray-100 font-comfortaa w-3/4 md:w-2/3 rounded-full p-2 text-center" onClick = {sendPasswordReset}>Send Email</button>
                        </div>
                    </div>

                </div>  
            </div>
        </>
    )
}