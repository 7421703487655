import React, { createContext, useContext } from 'react';
import useBudgetData from '../hooks/userBudgetData.js';

// Create Context
const BudgetDataContext = createContext();

// Provider Component
export const BudgetDataProvider = ({ children }) => {
    const {loading, createData, budgetData, fetchBudgetData, updateData, addData, createBudget, fetchProjectedBudget, projected_data, updateCurrent, updateProjected} = useBudgetData(); // Use useBudgetData here

    return (
        <BudgetDataContext.Provider value={{ loading, createData, budgetData, fetchBudgetData, updateData, addData, createBudget, fetchProjectedBudget, projected_data, updateCurrent, updateProjected}}> 
            {!loading && children}
        </BudgetDataContext.Provider>
    );
};

// Custom hook to use the budget data context
export const useBudget = () => useContext(BudgetDataContext);
