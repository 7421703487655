import React, { useEffect, useState } from 'react';
import { useSpring, animated } from "react-spring";

function DropDownInput({ objects, className, onSelect, idx, originalState, currentArr, toggleOffDiv, isOpen }) {
  const [showDropdown, setShowDropdown] = useState(false);

  const displayDropdown = () => {
    toggleOffDiv(Math.abs(idx))
  };

    useEffect(() => {
      setShowDropdown(isOpen);
  }, [isOpen]);

  const selectElement = (option, idx) => {
    setShowDropdown(false);  // Close the dropdown
    onSelect(option, idx);   // Notify parent about the selection
    toggleOffDiv(idx);   
  }

  const dropdownAnimation = useSpring({
    to: { 
      opacity: showDropdown ? 1 : 0,
      transform: showDropdown ? 'translateY(-10px)' : 'translateY(0px)' 
    },
    from: { opacity: 0, transform: 'translateY(0px)' },
    config: { mass: .8, tension: 210, friction: 20 },
    immediate: key => !showDropdown && key === 'opacity'
  });

  return (
    <div className={className}>
      <div className="w-full flex cursor-pointer flex-row pr-1 justify-between text-center" onClick={displayDropdown}>
        <span>{
          idx === -1 ? currentArr[Math.abs(idx)].rate : (currentArr[idx].answer === null ? "Select" : currentArr[idx].answer)
        }</span>
        <svg className={`h-4 w-3 ${showDropdown ? "" : "rotate-180"} h-full transition duration-500 inset-y-0 fill-white`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 22h-24l12-20z"/></svg>
      </div>

      {showDropdown && 
        <animated.div style={{ ...dropdownAnimation, willChange: 'transform, opacity' }} className='transition duration-500 absolute -bottom-1 w-full'>
          <svg className="absolute rounded-lg fill-white w-8 h-8 opacity-[98%]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 22h-24l12-20z"/></svg>
          <div className='absolute w-full mt-4 rounded-lg bg-white opacity-[98%]'> 
            {objects.map(obj => (
              <div key={obj.id} onClick={() => selectElement(obj.option, idx)} className='text-black flex justify-center py-2 rounded-lg hover:bg-blue-200 transition duration-300 font-sans cursor-pointer'>
                <div>{obj.option}</div>
              </div>
            ))}
          </div>
        </animated.div>
      }
    </div>
  );
}

export default DropDownInput;
