import squiggle from "../images/background-shape.webp"
import squiggleDark from "../images/image.webp"
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';


export default function LandingPage2() {
    const navigate = useNavigate();

    const redirectToDashboard = () => {
        navigate("/dashboard")
    }

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const animation = useSpring({
        to: { transform: `scale(${1 +  Math.min(2*scrollY, 0.5)}) rotate(${ Math.min(2*scrollY, 90) }deg)` }, // Adjusted factors
        from: { transform: 'scale(1) rotate(0deg)' },
    });

    return (
        <>
        <div className=" w-screen h-screen font-noto relative bg-finblue dark:bg-inkfin transition duration-500">
            <div className="h-full w-full absolute flex justify-center items-center -mt-24 animate-fade-up">
                <img src={squiggle} className="scale-125 md:scale-75 lg:scale-90 xs:scale-90 dark:hidden transition duration-500"/>
                <img src={squiggleDark} className="scale-125 md:scale-75 lg:scale-90 xs:scale-90 hidden dark:flex transition duration-500"/>
            </div>
            <div className="flex w-full h-full flex-col relative justify-center items-center mt-5 px-5 animate-fade-up">
                <div className="z-10 flex flex-col justify-center items-center">                
                    <div className="flex flex-col md:mt-32 lg:mt-0 w-full md:w-1/2 flex-grow text-5xl md:text-6xl lg:text-7xl text-center font-extrabold dark:text-white transition duration-500 text-shadow-lg">
                        AI stock analysis and personal budget 
                        <em className="flex-1 text-[#f3fd02] md:mb-28 lg:mb-0 dark:text-[#FFD700] transition duration-500 text-center items-center">optimization.</em>
                    </div>
                    {/* <div className="mt-5 pt-10 text-sm md:text-xl text-gray-600 dark:text-gray-100 transition duration-500 text-center">Enter the world of finance today with StockFin.</div> */}
                    <div onClick={redirectToDashboard} className = "mt-28 md:mt-0 lg:mt-28 p-5 md:mb-52 lg:mb-0 shadow-2xl bg-inkfin dark:bg-finblue transition duration-500 text-gray-800 text-white transition duration-500 rounded-lg w-3/4 lg:w-1/5 font-lg text-center font-bold leading-loose hover:scale-110 duration-500 cursor-pointer"><p className="text-2xl text-shadow-lg">Get Started</p></div>
                    <div className="mt-1 text-black opacity-50 sm:text-sm text-xs dark:text-white transition duration-500">✓ Start for free. No credit card required.</div>
                </div>
            </div>
        </div>
        </>
    )
}