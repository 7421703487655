import React, {useState, useRef, useEffect, useContext} from 'react'
import { useSpring, animated } from 'react-spring';
import { UserContext } from '../hooks/UserContext.js';

function SelectionMenu({data, onSelect, currentValue, se}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const menuRef = useRef(null);
    const toggleRef = useRef(null); // Ref for the toggle button (SVG)
    const [open, setOpen] = useState(false)
    const { userQualities } = useContext(UserContext);

    const toggleMenu = (event) => {
        event.stopPropagation(); // Prevent click from bubbling to document
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleOption = (obj) => {
        if (obj.name === 'Finbot') {
            if (userQualities?.tier === "premium") {
                onSelect(obj)
                setIsMenuOpen(!isMenuOpen)
            }
            else {
                se("Please upgrade to use this feature")
            }
        }
        else {
            onSelect(obj)
            setIsMenuOpen(!isMenuOpen)
        }

    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target) &&
                !toggleRef.current.contains(event.target)) { // Check if the click is outside menu and toggle button
                setIsMenuOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const slideFade = useSpring({
        onStart: () => {
            if (isMenuOpen) {
                setOpen(true)
            }
        },
        opacity: isMenuOpen ? 1 : 0,
        config : {tension : 150, friction : 20},
        transform: isMenuOpen ? `translateX(0)` : `translateX(120%)`, // Slide in from right when open; slide out to right when closed
        from: { opacity: 0, transform: 'translateX(100%)' },
        onRest: () => {
            // Only set 'open' to false when the menu is closing, i.e., when isMenuOpen is false
            if (!isMenuOpen) {
                setOpen(false);
        }
        }
    });

  return (
    <>
    <div className='flex w-full h-full flex-col font-noto z-50 '>
        <svg ref = {toggleRef} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" onClick = {toggleMenu} className={`w-6 h-6 flex place-self-end cursor-pointer z-30  top-1 right-1 transition duration-500`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
        </svg>
        <animated.div ref = {menuRef} style={slideFade} className={`w-full ${!open && "hidden"} h-full flex -mt-2 z-50 rounded-xl shadow-xl bg-white`}>
            <div className='flex h-full py-4 w-full justify-center items-center' >
                <div className= 'flex flex-col space-y-4 justify-center w-full '>
                {data.map((obj, idx) => {
                    if (currentValue !== obj.name) { // Assuming currentValue is a name string to compare
                        return (
                            <React.Fragment key={idx}>
                                <div onClick={() => toggleOption(obj)} className={`px-4 ${obj.name === "Finbot" ? `${userQualities?.tier === "premium" ? "hover:bg-gray-200 cursor-pointer" : "cursor-not-allowed blur-[1px]"}` : "hover:bg-gray-200 cursor-pointer" } py-2 flex transition duration-500 w-full flex-row gap-2`}>
                                    <div className='text-black'>{obj.svg}</div>
                                    <div className='text-black'>{obj.name}</div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    return null; // Explicitly return null if condition is not met
                })}
                </div>
            </div>
        </animated.div>
    </div>
    </>
  )
}

export default SelectionMenu