import React, {useContext, useEffect} from 'react'
import { useCheckout } from '../components/checkoutButton.js';


function Account() {
    const { sendToCustomerPortal } = useCheckout();

    return (
        <div className='w-[100%] h-[100%]'>
            {}
        </div>
    )
}

// 404 Page  import Spline from '@splinetool/react-spline';  <Spline scene='https://prod.spline.design/EQ9vjuA7C-kJ3HlZ/scene.splinecode' />

export default Account