import { useEffect, useState } from "react";

export default function useDarkMode() {
    // Initialize theme state with value from localStorage
    const storedTheme = localStorage.getItem('theme');
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const defaultTheme = storedTheme ? storedTheme === 'dark' : prefersDarkMode;
    const [theme, setTheme] = useState(defaultTheme);
    const colorTheme = theme ? "dark" : "light";

    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove('light', 'dark');
        root.classList.add(colorTheme);

        // Update localStorage whenever theme changes
        localStorage.setItem('theme', colorTheme);
    }, [colorTheme]);

    return [theme, setTheme];
}
