import React, {useState, useEffect, useRef} from 'react'
import {useSpring, animated} from "react-spring"

function MovingButton(props) {
    const [hovering, setHovering] = useState(false);
    const [length, setLength] = useState(0);
    const [background, setBackground] = useState("transparent")
    const parentRef = useRef(null); // Ref for the parent element

    const onCompletion = () => {
        setBackground("[#00CC99]")
        if (props.action) {
            props.action()
        }
    }


    useEffect(() => {
        let interval;
        const parentWidth = parentRef.current ? parentRef.current.offsetWidth : 0;

        if (hovering) {
            interval = setInterval(() => {
                setLength(prevLength => {
                    if (prevLength < parentWidth) {
                        return prevLength + 1; // Increment length by 1
                    } else {
                        clearInterval(interval); // Stop the interval if length reaches parent width
                        onCompletion()
                        return prevLength;
                    }
                });
            }, 2);
        }

        return () => {
            clearInterval(interval);
            setLength(0); // Reset length when not hovering
        };
    }, [hovering]);

    const arrowStyle = useSpring({
        width: length,
        config: { tension: 100, friction: 10 }
    });
    
    const parentWidth = parentRef.current ? parentRef.current.offsetWidth : 0;
    const alphaValue = parentWidth > 0 ? length / parentWidth : 0;
    const backgroundColor = `rgba(0, 204, 153, ${alphaValue})`;

    const nextTextStyle = useSpring({
        opacity: 1 - alphaValue*2, // As alphaValue increases, opacity decreases
    });

  return (
    <>
    <animated.div onClick = {() => props.action()} className={`w-[90%] md:w-1/3 h-10 border-2 mt-10 rounded-xl flex justify-center items-center relative cursor-pointer`} style={{backgroundColor}} onMouseEnter={() => setHovering(true)}  onMouseLeave={() => setHovering(false)}>
        <div ref={parentRef} className='flex flex-row items-center h-full w-[95%]'>
            <animated.span style={arrowStyle} className="h-1 bg-white flex left-2 flex items-center">
            </animated.span>
            <svg className = "flex fill-white w-4 h-4 rotate-90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 22h-24l12-20z"/></svg>
            <animated.div style={nextTextStyle} className='absolute right-2 text-white text-sm opacity-75'>Next</animated.div>
        </div>
    </animated.div>
     </>
  )
}

export default MovingButton