import React, { useState, useEffect, useRef, useContext, createContext } from 'react';
import MovingButton from '../components/movingButton.js';
import { useSpring, animated } from 'react-spring';
import DropDownInput from '../components/dropdownInput.js';
import MonthlyExpenses from "../information/possible-expenses.txt"
import VariableExpenses from "../information/variable-expenses.txt"
import Sparkles from "../images/sparkles.gif"
import { UserContext } from '../hooks/UserContext.js';
import {useBudget} from "../hooks/budgetDataContext.js"
import Loading from '../pages/loading.js';

function Initialize() {
    const { updatePremium, currentUser } = useContext(UserContext);
    const { createData } = useBudget()
    const incomeRef = useRef(0)
    const initial_arr = [
        { question: 'Whats your current employment status?', answer: null, isOpen : false },
        { question: 'What is your approximate income?', answer: "USD", rate: "Yearly", income : null, isOpen : false},
        { question: 'How would you like to invest?', answer : null, isOpen : false},
        { question: 'How do you spend your money?', answer : null, isOpen: false}
    ]    
    const questionsRef = useRef(initial_arr) // The refernce array for user
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [error, setError] = useState("")
    const [monthlyExpenses, setMonthlyExpenses] = useState([])
    const [variableExpenses, setVariableExpenses] = useState([])
    const [pageIndex, setPageIndex] = useState(0);
    const [loading, setLoading] = useState(false)


   


    const goBack = () => { 
        setPageIndex(pageIndex-1)
        console.log("Back a page")
    }

    useEffect(() => {
        // Assuming the file is in the public folder
        fetch(MonthlyExpenses)
            .then(response => response.text())
            .then(text => {
                const lines = text.split('\n');
                setMonthlyExpenses(lines);
            })
            .catch(error => console.error('Error reading expenses file:', error));

        fetch(VariableExpenses)
            .then(response => response.text())
            .then(text => {
                const lines = text.split('\n');
                setVariableExpenses(lines);
            })
            .catch(error => console.error('Error reading expenses file:', error));
    }, []);

    const showSymbol = () => {
        switch (questionsRef.current[1].answer) {
            case "USD":
                return "$"
            case "EUR":
                return "€"
            case "AUD":
                return "A$"
            default:
                return "";
        }
    }

    const toggleOffDiv = (index) => {
        if (openDropdownIndex === index) {
            // Close the current dropdown
            setOpenDropdownIndex(null);
        } else {
            // Open the new dropdown
            setOpenDropdownIndex(index);
        }
    };
    
    const handleSelect = (object, idx) => {
        if (idx === -1) {
            questionsRef.current[1].rate = object
        }
        else {
            questionsRef.current[idx].answer = object
        }
        const symbolElement = document.getElementById("symbol-display");
        if (symbolElement) {
            symbolElement.textContent = showSymbol();
        }
    };

    const checkData = () => {
        let isValid = true
        for (let i = 0; i < questionsRef.current.length; i+=1) {
            if (i===1){
                if (questionsRef.current[i].income === null) { setError("Please enter an income"); isValid = false }
            }
            else {
                if (questionsRef.current[i].answer === null) { setError("Please select all options"); isValid = false}
            }
        }
        if (isValid){
            goToNextPage()
        }
    }

    const Landing = ({style, goToNextPage, pulse}) => (
        <animated.div style={style} className='w-full xxs:w-5/6 h-4/6 bg-[#24305E] flex items-center justify-center xs:border-2 dark:border-white rounded-xl shadow-3xl'>
            <div className='flex flex-col w-full h-full justify-center  items-center font-noto'>
                <h1 className='text-4xl md:text-6xl text-center font-extrabold text-[#00CC99]'>Thanks for choosing StockFin!</h1>
                <h5 className='text-2xl md:text-4xl mt-5 text-white dark:text-black'>Let's get you started.</h5>
                <animated.div style={pulse} className="w-full flex justify-center"><MovingButton onClick = {goToNextPage} action={goToNextPage} /></animated.div>
            </div>
        </animated.div>
    );
    
    const Finance = ({style}) => (
        <animated.div style={style} className='flex w-full md:w-[80%] h-full md:h-5/6 relative bg-[#24305E] rounded-lg'>
            <div className='flex flex-col sm:flex-row'>
            <div className='flex flex-col justify-center relative text-white w-full mt-24 md:w-3/4 items-center text-center font-noto'>
            <div className='font-bold pb-5 text-3xl sm:text-5xl md:text-5xl lg:text-6xl'>
                Let's get to know you
                <span className='text-[#e0b932] ml-2 relative'>
                    <span className='z-50'>better.</span>
                    <img src={Sparkles} className="absolute opacity-32 sm:-top-3 -top-5 z-10 -left-3 w-64 h-28" />
                </span>
            </div>                
            <div className='w-5/6 pt-5 font-bold text-md text-gray-500 border-t-2 border-double'>I know, I know. Who likes to give information, but trust me... Its worth it. </div>
            </div>

            <div className='w-full md:w-5/6 h-full flex flex-col flex-grow mt-5 md:mt-0 md:border-l-4 font-noto relative md:justify-center items-center'>
                <div className='font-noto w-full md:w-3/4 z-50 text-white'>
                    <div className='text-center text-lg'>What is your current employment status?</div>
                    <DropDownInput isOpen={openDropdownIndex === 0} toggleOffDiv={() => toggleOffDiv(0)} currentArr = {questionsRef.current} originalState={"Select"} onSelect={handleSelect} idx = {0} className = {'flex bg-transparent mt-2 mx-auto border-2 rounded-lg p-1 px-1 w-5/6 justify-center relative cursor-pointer'} objects={[{id : 1, option : "Full-Time"}, {id : 2, option : "Part-Time"},  { id : 3, option : "Unemployed"}, { id : 4, option : "Student"}, { id : 5, option : "Looking"}]} />
                </div>
                <div className='font-noto w-full md:w-3/4 z-40 mt-5 text-white'>
                    <div className='text-center text-lg'>What is your approximate income?</div>
                    <div className='flex flex-row' >
                    <div className='flex bg-transparent mt-2 mx-auto border-2 rounded-lg p-1 px-1 w-1/3 relative'>
                        <div id="symbol-display" className='absolute inset-y-0 left-1 pl-1 flex items-center pointer-events-none'>
                            {showSymbol()}
                        </div>
                            <input type='number' className='pl-6 bg-transparent w-full ' placeholder = {incomeRef.current} onChange={(e) => {incomeRef.current = e.target.value; questionsRef.current[1].income = e.target.value;}} />
                    </div>                        
                    <DropDownInput isOpen={openDropdownIndex === 1} toggleOffDiv={() => toggleOffDiv(1)} currentArr = {questionsRef.current} originalState={"USD"} className={'flex bg-transparent mt-2 mx-auto border-2 w-1/4 relative rounded-lg p-1 px-1 cursor-pointer'} onSelect={handleSelect} idx = {1} objects={[{id:0, option:"USD"}, {id:2, option: "EUR"}, {id:3, option:"AUD"}]}/>
                    <DropDownInput isOpen={openDropdownIndex === 2} toggleOffDiv={() => toggleOffDiv(2)} currentArr = {questionsRef.current} originalState={"Yearly"} className={'flex type:number bg-transparent mt-2 mx-auto border-2 w-[27%] relative rounded-lg p-1 px-1 cursor-pointer'} onSelect={handleSelect} idx = {-1} objects={[{id:0, option:"Yearly"}, {id:2, option: "Monthly"}, {id:3, option:"Bi-Weekly"}, {id:3, option:"Weekly"}]}/>
                    </div>
                </div>

                <div className='font-noto w-full md:w-3/4 z-30 mt-5 text-white'>
                    <div className='text-center text-md md:text-lg'>How would you like to invest? </div>
                    <DropDownInput isOpen={openDropdownIndex === 3} toggleOffDiv={() => toggleOffDiv(3)} currentArr = {questionsRef.current} className={'flex bg-transparent mt-2 mx-auto border-2 w-5/6 relative rounded-lg p-1 px-1'} originalState={"Select"} onSelect={handleSelect} idx = {2} objects={[{id:0, option:"Risky"}, {id:1, option:"Cautious"}, {id:2, option:"Balanced"}]}/>
                </div>

                <div className='font-noto w-full md:w-3/4 mt-5 z-20 text-white'>
                    <div className='text-center text-md md:text-lg'>How do you spend your money?</div>
                    <DropDownInput isOpen={openDropdownIndex === 4} toggleOffDiv={() => toggleOffDiv(4)} currentArr = {questionsRef.current} className={'flex bg-transparent mt-2 mx-auto border-2 w-5/6 relative rounded-lg p-1 px-1'} originalState={"Select"} onSelect={handleSelect} idx = {3} objects={[{id:0, option:"Frugally"}, {id:1, option:"Conservative"}, {id:2, option:"Impulsive"}, {id:3, option:"Philanthropic"}]}/>
                </div>
                <div className='text-center text-lg font-comfortaa font-bold text-red-600'>{error}</div>
                <div className='flex pt-10 md:absolute bottom-2 pb-10 sm:pb-0 justify-center w-full '>
                    <button onClick = {() => checkData()} className='border-2 w-5/6 p-1 place-self-end rounded-xl hover:bg-[#00CC99] transition duration-1000 flex flex-row text-[#00CC99]'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white ml-2 mr-2"><path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" /></svg>Next</button>
                </div>
            </div>
            <div className='absolute top-4 right-4'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white" onClick={goBack}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>
            </div>
            </div>
        </animated.div>
    );
                        
        
    const [finalExpenses, setFinalExpenses] = useState([])

    const Expenses = ({style}) => {
        const [priceInput, setPriceInput] = useState("")
        const [categoryInput, setCategoryInput] = useState("")
        const [showExamples, setShowExamples] = useState(false)
        const [currentExpense, setCurrentExpense] = useState({category : null, price : null, type : "Fixed Expense"})
        const [monthlyError, setMonthlyError] = useState("")
        const [addExpense, setAddExpense] = useState(true) // Create element?
        const [dataArr, setDataArr] = useState([])

     // States for Fixed Expenses
        const [explainFixed, setExplainFixed] = useState(false)
        const [explainVariable, setExplainVariable] = useState(false)
        const [seconds, setSeconds] = useState(5);
        const [finalVariable, setFinalVariable] = useState([])
        const [showVariableExamples, setShowVariableExamples] = useState(false)
        const [addVariable, setAddVariable] = useState(true) // Create element?
        const [currentVariable, setCurrentVariable] = useState({category : null, price : null, type : "Variable Expense"})
        const [variableInput, setVariableInput] = useState("")
        const [variableCategory, setVariableCategory] = useState("")    

        const changeCat = (newCategory) => {
            console.log(newCategory)
            setCategoryInput(newCategory)
          };
          
          const changePrice = (newPrice) => {
            setPriceInput(newPrice)

          };
        useEffect(() => {
            // console.log('SHOW EXAMPLE: ', showExamples, "ADD EXPENSE: ", addExpense)
            if (showExamples === true) {
                setAddExpense(false)
            }
        }, [showExamples, addExpense])

        useEffect(() => {
            // Only set up the interval if the timer is above 0
            if (seconds > 0) {
                const interval = setInterval(() => {
                    setSeconds(seconds => seconds - 1); // Decrement the seconds
                }, 1000); // Update every second
                return () => clearInterval(interval);
            }
            else{
                setMonthlyError("")
                setSeconds(5)
            }
        }, [seconds, monthlyError]); 

        // States for variable expenses 

        
        const fadeIn = useSpring({
            opacity: (explainFixed ? explainFixed : explainVariable) ? .9 : 0,
            config: { duration: 250 } // Adjust duration as needed
        });

        const fadeText = useSpring({
            opacity: monthlyError ? 1 : 0,  // Fade in if there's an error, otherwise fade out
            from: { opacity: 0 },
        });

        const checkData = () => {
            if (dataArr.length < 3) {
                console.log("error")
                setMonthlyError("Please enter a few more! The more you add, the better we can describe you!")
            }
            else {
                unionFinalData()
            }
        }

        const unionFinalData = async () => {
            // Concat combined data
            const finalData = [{BackgroundInformation: questionsRef}, {MonthlyExpenses: dataArr}]
            try{
                setLoading(true)
                createData(finalData)
                // Do something with the response data
            } catch (error) {
                console.error('Error sending data:', error);
            }
        }



        const setExampleExpense = (expense, type) => {
            setAddExpense(true)
            setCurrentExpense({category : expense, price : null, type : type})
            setShowExamples(false)
            setAddExpense(true)
        }

        const cancelElement = () => {
            setCurrentExpense({category:null, price:null, type : "Fixed Expense"}); 
            setPriceInput(""); 
            setCategoryInput("")
            setAddExpense(false)
        }

        const saveElement = () => {
            if (categoryInput != "") { 
                setCurrentExpense(prevExpense => ({
                    ...prevExpense,
                    category: categoryInput,
                    price: priceInput
                }));
            }
            else { 
            setCurrentExpense(prevExpense => ({
                ...prevExpense,
                price: priceInput
            }));
            }
        }

        const removeFinalElement = (idx) => {
            console.log('Index to remove:', idx);
            setFinalExpenses(prevExpenses =>
                prevExpenses.filter((item, index) => index !== idx)
            );
        };

        useEffect(() => {
            if (currentExpense.price != "" && currentExpense.price != null) {
                setFinalExpenses(prevExpenses => [...prevExpenses, currentExpense]);
                cancelElement()
                setShowExamples(false)
            }
            const mergeArrays = (arr1, arr2) => {
                const map = new Map();
    
                // Merge two arrays
                [...arr1, ...arr2].forEach(item => {
                    // Create a composite key
                    const key = `${item.category}-${item.price}`;
                    map.set(key, item);
                });
                return Array.from(map.values());
            };
            const setUnion = mergeArrays(finalExpenses, finalVariable)
            if (setUnion.length > 3) { setDataArr(setUnion); }
        }, [currentExpense, currentVariable])

        return loading ? (<Loading />) : (    
            
            <animated.div style = {style} className='flex flex-col flex-contain w-full relative sm:w-[98%] h-full md:h-[85%] shadow-xl text-white bg-[#24305E] md:rounded-lg overflow-hidden'>
            <div className='absolute flex-1 bottom-4 inset-x-0 hidden md:flex md:flex-col justify-center items-center'>
                <animated.div style={fadeText} className='w-1/4 text-red-600 text-center pb-2'>{monthlyError && <animated.div style={fadeText}>{monthlyError}</animated.div>}</animated.div>
                <button className='border-2 p-2 w-[15%] rounded-xl z-10 cursor-pointer hover:bg-[#00CC99] hover:scale-105 transition duration-1000 ' onClick={checkData}>Finish</button>
            </div>
            
            <div className='w-full flex flex-col relative items-center mt-10 '>
                <div className='text-7xl font-noto font-extrabold text-center'>Almost there!</div>
                <div className='font-noto text-xl mt-5 text-gray-400 w-full flex justify-center relative'><div className=' flex absolute pb-2 border-b-2 border-gray-400'>Let's explore your expenses.</div></div>
            </div>
            
            
            
            
            <div className='w-full h-full flex pb-4 flex-col md:gap-0 md:flex-row'>
                <div className='w-full h-full mt-16 md:mt-0 flex relative flex-row items-start'>
                    <div className='flex absolute flex-grow w-full z-30 md:w-1/4 md:h-5/6 h-44 top-20 justify-center md:top-0'>
                        {
                            showExamples && (
                                <div className='flex h-full w-3/4 md:w-full '>
                                    <div className='overflow-auto w-full h-full px-4 py-4 mb-5 border-r-2'>
                                        {monthlyExpenses.map((expense, index) => (
                                        <React.Fragment key={index}>
                                            <div className='flex flex-grow-0 pb-2 flex-shrink min-h-[10%] border-2 rounded-xl text-center py-1 mb-2 justify-center items-center cursor-pointer' onClick = {() => setExampleExpense(expense, "Fixed")} key={index}>{expense}</div>
                                        </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            )
                        }
                            <div className='absolute hidden md:flex justify-center w-full underline -bottom-12'>
                                    <div className = "cursor-pointer" onClick={() => setShowExamples(!showExamples)}>
                                        {showExamples ? "Hide" : "Show"} Examples
                                    </div>
                            </div>
                    </div>

                    <div className='w-full h-[33rem] md:h-[30rem] lg:h-[31rem] xl:h-[35rem] flex-row flex place-items-center justify-center'>
                        <div className='w-full h-full flex flex-col md:flex-row justify-between items-center p-2'>

                            <div className='w-full h-full flex flex-col items-center relative'>
                                <div className='w-full mt-2 md:mt-16 relative flex flex-row justify-center items-center'>
                                    <div className='flex ml-6 text-lg font-noto p-2 rounded-lg border-2 hover:scale-105 transition duration-500 cursor-pointer' onClick={addExpense ?  undefined : () => {setAddExpense(true); setShowExamples(false)}} >Add Expense</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" onMouseEnter = {() => setExplainFixed(true)} onMouseLeave={() => setExplainFixed(false)} className="flex cursor-pointer ml-2 w-8 h-8"><path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" /></svg>
                                </div>
                                {explainFixed && 
                                <animated.div style={fadeIn} className='absolute w-2/6 top-[18%] flex flex-grow py-5 font-noto rounded-xl z-10 shadow-xl bg-black'>
                                    <animated.div style={fadeIn} className="w-full text-center flex justify-center items-center">
                                        <div className='w-5/6 text-wrap flex flex-col'>
                                            <div>Please enter any monthly expense. This can consist of:</div>
                                            <div className='mt-2'>&#8226; Rent for a house.</div>
                                            <div>&#8226; Your monthly DoorDash obsession.</div>
                                        </div>
                                    </animated.div>
                                </animated.div>
                                }
                                <div>{addExpense && !showExamples? (
                                <>
                                <div className= {`flex flex-col w-full lg:px-10 mt-5 mb-2 border-2 border-gray-400 text-gray-400 border-dashed p-4 rounded-xl `}>
                                    <div className='pt-2 pb-6 space-y-4 flex z-50 justify-center flex flex-col text-center'>
                                        {currentExpense.category ? (<div>Monthly Expense: {currentExpense.category} </div>) : (<div>Monthly Expense: <input type = "text" className='bg-transparent border-b-2 w-1/2 focus:outline-none' onChange={(e) => {changeCat(e.target.value)}} /></div>)}
                                        {currentExpense.price ? (<div>Price: ${currentExpense.price} </div>) : (<div> Price:    $<input className='bg-transparent ml-1 focus:outline-none border-b-2 w-1/2' onChange={(e) => {changePrice(e.target.value)}}/> </div>)}
                                    </div>
                                <div className='flex space-x-4 min-w-full z-50 justify-between flex flex-row mt-2 '>
                                    <button onClick = {() => saveElement()} className='border-2 w-1/2 border-dashed hover:scale-105 transition duration-500 px-2 py-1 rounded-md '>Save</button>
                                    <button onClick = {() => cancelElement()} className='border-2 w-1/2 border-dashed hover:scale-105 transition duration-500 px-2 py-1 rounded-md '>Cancel</button>
                                </div>
                                </div>

                                </>

    ) :                         (<div></div>)}</div>
                            </div>
                        <div className='flex flex-col md:absolute top-0 right-0 w-full pt-2 md:pt-0 md:w-1/4 h-5/6 justify-end items-center'>
                                <div className='flex w-[95%] justify-between flex-col relative flex-grow'>
                               <div className='flex overflow-y-auto  w-full flex-grow h-32 flex-col'>
                                {
                                    finalExpenses.length > 0 && finalExpenses.map((expense, idx) => (
                                        <React.Fragment key={idx}>
                                            <div className='flex flex-shrink-0 min-h-[20%] flex-col relative mt-5 py-3 mx-auto justify-center items-center w-3/4 border-2 rounded-lg '>
                                                <div onClick = {() => removeFinalElement(idx)} className='absolute right-1 top-1 cursor-pointer'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" /></svg></div>
                                                <div>Expense: {expense.category}</div>
                                                <div>Price: ${expense.price}</div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                }
                                </div>
                            </div>
                        </div>
                        </div>

                    </div>
            </div>  

                            <div className='w-full bottom-4 p-4 flex flex-col flex-shrink md:hidden justify-center '>
                                <animated.div style={fadeText} className='w-full pb-4 text-red-600 text-center'>{monthlyError ? <animated.div style={fadeText}>Yes</animated.div> : <div></div>}</animated.div>
                                <div className='flex md:hidden pb-2 underline justify-center w-full'>
                                    <div onClick={() => setShowExamples(!showExamples)}>
                                        {showExamples ? "Hide" : "Show"} Examples
                                    </div>
                                </div>
                                <button className='border-2 p-2 w-full rounded-xl z-10 cursor-pointer hover:bg-[#00CC99] hover:scale-105 transition duration-1000 ' onClick={checkData}>Finish</button>
                            </div>


                                <div className='absolute top-4 right-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" onClick={goBack}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                                    </svg>
                                </div>

                </div>
        </animated.div>
        )
    };

    const [animating, setAnimating] = useState(false);

    const animationProps = useSpring({
        transform: animating ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, 0, 0)', // Updated this line
        onRest: () => {
            if (animating) {
                setPageIndex((prevIndex) => (prevIndex + 1) ); // Assuming 2 pages for now
                console.log(pageIndex)
                setAnimating(false);
            }
        },
        config: { duration: 200 }
    });

    const pulse = useSpring({
        from: { transform: 'scale(1)' },
        to: async (next, cancel) => {
            while (1) {
                await next({ transform: 'scale(1.05)' });
                await next({ transform: 'scale(1)' });
            }
        },
        config: { duration: 1000 },
    }); 

    const goToNextPage = () => {
        setAnimating(true);
    };

    useEffect(() => {
        // reset animation when page changes
        setAnimating(false);
    }, [pageIndex]);

    let PageComponent;
    if (pageIndex === 0) {
        PageComponent = <Landing style={animationProps} goToNextPage={goToNextPage} pulse={pulse}/>;
    } else if (pageIndex === 1) {
        PageComponent = <Finance style={animationProps} />;
    } else if (pageIndex === 2) {
        PageComponent = <Expenses style={animationProps} />
    }

    return (
        <div className='w-full max-h-[98%] rounded-lg flex flex-grow bg-[#A8D0E6] dark:bg-transparent justify-center items-center'>
            {PageComponent }
        </div>
    );
}



export default Initialize



/*

                                */