import React, {useContext, useEffect, useState} from 'react'
import finlogo from "../images/finlogo.png"
import finlogoDark from "../images/finlogoDark.png"
import { UserContext } from '../hooks/UserContext.js';
import { useNavigate, redirect } from "react-router-dom";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { ReactComponent as Squares} from "../images/SquaresAndSparkles (2).svg"
import { useCheckout } from '../components/checkoutButton.js';
import LoadingBar from './loadingBar.js';

const FadeUpAnimation = ({ children, style }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1
    });
  
    const animation = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0)' : 'translateY(75px)',
      config: { duration: 500 },
    });
  
    return <animated.div style={{ ...animation, ...style }} ref={ref}>{children}</animated.div>;
  };

const FadeRightAnimation = ({ children, style }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1
    });
  
    const animation = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateX(0)' : 'translateX(-25px)',
      config: { duration: 500 }
    });
  
    return <animated.div style={{ ...animation, ...style }} ref={ref}>{children}</animated.div>;
};

const UpAnimation = ({ children, style }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1
    });
  
    const animation = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0)' : 'translateY(75px)',
      config: { 
        tension: 170, // Control speed
        friction: 26, // Control how the animation slows down
    },
      delay: 200
    });
  
    return <animated.div style={{ ...animation, ...style }} ref={ref}>{children}</animated.div>;
};

const UpAnimationDelay = ({ children, style }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1
    });
  
    const animation = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0)' : 'translateY(75px)',
      config: { 
        tension: 170, // Control speed
        friction: 26, // Control how the animation slows down
    },
      delay: 500
    });
  
    return <animated.div style={{ ...animation, ...style }} ref={ref}>{children}</animated.div>;
};

const LeftAnimation = ({ children, style }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateX(0)' : 'translateX(-25px)',
        config: { 
            tension: 170, // Control speed
            friction: 26, // Control how the animation slows down
        },
        delay: 200
    });

    return <animated.div style={{ ...animation, ...style }} ref={ref}>{children}</animated.div>;
};

export default function Pricing() {
    const [loading1, setLoading1] = useState(false) // Set to false when ready
    const [loading2, setLoading2] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);


    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { sendToCheckout } = useCheckout();

    const product_keys = {
        premium : "price_1OhQEAE9WDW4fbdqkrmVCBEf",
    }
    const redirectToDashboard = () => {
        navigate("/dashboard")
    }

    const redirectToPayment = (url) => {
        const newWindow = window.open(url, '_blank', "noopener,noreferrer");

        if (newWindow) newWindow.opener = null;
    }

    const checkOut = (plan) => {
        if (plan === "premium") {
            setLoading2(true)
            sendToCheckout(product_keys.premium)
        }
        setIsDisabled(true);
    }

    return (
        <>
            <div id='pricing' className='flex h-full w-full justify-center pt-32'>
                {/* {loading && (
                    <LoadingBar /> 
                )} */}
                <FadeUpAnimation style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div className="w-[80%] overflow-hidden my-10 rounded-xl bg-inkfin dark:bg-finblue shadow-darkfin dark:shadow-blue-lg transition duration-500">
                    <div className='flex flex-col md:flex-row w-full h-full items-center'>
                        <div className='flex flex-col w-full md:w-1/2 h-full py-5 md:py-14 lg:pl-14 font-noto z-10 text-center lg:text-start'>
                            <LeftAnimation className='text-start'>
                            <div className='text-xl md:text-3xl lg:text-5xl text-white transition-duration-500 font-bold'>Try StockFin free today</div>
                            </LeftAnimation>
                            <UpAnimation className='text-start'>
                            <div className='text-gray-100 dark:text-black tranistion duration-500 opacity-50 text-xs md:text-lg mt-5 px-5 lg:px-0'>Optimize your budget and get stock insights in seconds.</div>
                            </UpAnimation>
                            <UpAnimation className='text-start'>
                            <div className='text-gray-100 dark:text-black tranistion duration-500 opacity-50 text-xs md:text-lg mt-5 md:mt-0'>No credit card required.</div>
                            </UpAnimation>
                            <UpAnimation delay={300}>
                            <div onClick={redirectToDashboard} className='mx-auto lg:mx-0 w-1/2 text-center rounded-lg mt-8 text-lg md:text-3xl text-black text-opacity-85 shadow-lg leading-loose font-bold bg-white hover:scale-110 duration-500 cursor-pointer'>Talk to Fin</div>
                            </UpAnimation>
                        </div>
                        <div className='flex items-center relative flex-col w-full md:w-1/2 h-full justify-center'>
                            <div className='flex rounded-full w-32 h-32 md:w-56 md:h-56 lg:w-90 h-90 my-10 lg:mr-16 mx-auto dark:bg-inkfin transition duration-500 bg-finblue z-10 shadow-blue-lg dark:shadow-lg justify-center'>
                                <UpAnimationDelay style={{marginRight: 5}}>
                                <img src={finlogo} className='scale-125 hidden dark:flex'/>
                                <img src={finlogoDark} className='scale-125 dark:hidden'/>
                                </UpAnimationDelay>
                            </div>
                            <div className='flex flex-row z-0 absolute bottom-1/2 md:bottom-0 ml-16 mt-0 w-[150%] h-[150%]'>
                                <Squares className="dark:opacity-10 transition duration-500 opacity-75 scale-125"/>
                            </div>
                        </div>
                    </div>
                </div>
                </FadeUpAnimation>
            </div>
            <div className='flex justify-center w-[95%] mx-auto rounded-lg h-full mt-14 mb-20 bg-[#F4F4F4] dark:bg-[#0E131A] transition duration-500 py-10'>
                <div className='flex flex-col lg:flex-row text-center lg:text-start w-4/5 h-full justify-center gap-16 font-noto'>
                    <div className='flex flex-col h-full w-full lg:w-1/3 bg-white dark:bg-[#1f2937] transition duration-500 rounded-lg shadow-lg pl-5 pt-3 z-10 pb-10'>
                        <FadeRightAnimation>
                        <div className='text-black dark:text-white transition duration-500 opacity-50'>Free</div>
                        </FadeRightAnimation>
                        <FadeRightAnimation>
                        <div className='text-5xl dark:text-white transition duration-500 mt-5 font-bold'>$0</div>
                        </FadeRightAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex flex-row mt-10 gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                            </svg>
                            <div className=' text-sm md:text-base text-start'>Access to Budgeting Dashboard</div>
                        </div>
                        </FadeUpAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex items-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mb-0.5 mt-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                            </svg>
                            <div className='ml-2 inline text-sm md:text-base text-start'>Access to Finbot AI</div>
                        </div>
                        </FadeUpAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex items-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mb-0.5 mt-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                            </svg>
                            <div className='ml-2 inline text-sm md:text-base text-start'>30 texts to Finbot</div>
                        </div>
                        </FadeUpAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex items-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mb-0.5 mt-10">
                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                            </svg>
                            <div className='ml-2 inline text-sm md:text-base text-start'>AI Budgeting Feedback and Projection</div>
                        </div>
                        </FadeUpAnimation>
                        <UpAnimation>
                        <div onClick={redirectToDashboard} className='mt-20 w-1/2 rounded-lg bg-inkfin dark:bg-white transition duration-500 dark:text-black transition duration-500 text-white py-2 shadow-lg ml-[21%] text-center hover:scale-110 duration-500 cursor-pointer'>Get Started</div>
                        </UpAnimation>
                    </div>
                    <div className='flex flex-col h-full w-full lg:w-1/3 bg-white dark:bg-[#1f2937] transition duration-500 rounded-lg shadow-lg pl-5 pt-3 pb-10'>
                        <FadeRightAnimation>
                        <div className='text-black dark:text-white transition duration-500 opacity-50'>Premium</div>
                        </FadeRightAnimation>
                        <FadeRightAnimation>
                        <div className='text-5xl dark:text-white transition duration-500 mt-5 font-bold'>$9.99<p className='text-black dark:text-white transition duration-500 text-lg inline font-normal opacity-65'> / month</p></div>
                        </FadeRightAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex items-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mb-0.5 mt-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                            </svg>
                            <div className='ml-2 inline text-sm md:text-base text-start'>AI Projected Budget</div>
                        </div>
                        </FadeUpAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex items-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mb-0.5 mt-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                            </svg>
                            <div className='ml-2 inline text-sm md:text-base text-start'>Premium AI Feedback on Budget</div>
                        </div>
                        </FadeUpAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex items-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mb-0.5 mt-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                            </svg>
                            <div className='ml-2 inline text-sm md:text-base text-start'>Unlimited chats with Finbot AI</div>
                        </div>
                        </FadeUpAnimation>
                        <FadeUpAnimation>
                        <div class="text-current dark:text-white transition duration-500 flex items-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mb-0.5 mt-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                            </svg>
                            <div className='ml-2 inline text-sm md:text-base text-start'>+ All Free Features</div>
                        </div>
                        </FadeUpAnimation>
                        <UpAnimation>
                            <div onClick={!isDisabled ? () => checkOut("premium") : null} className={`mt-20 w-1/2 rounded-lg bg-inkfin flex justify-center relative dark:bg-white dark:text-black transition text-white py-2 shadow-lg ml-[25%] text-center duration-500 ${!isDisabled ? 'hover:scale-110 cursor-pointer' : 'cursor-not-allowed opacity-50'}`}>
                                <div>{isDisabled ? 'Loading...' : 'Get Started'}</div>
                                {loading2 && <div className='absolute right-2 top-3'>
                                    <svg aria-hidden="true" class="w-4 h-4 text-gray-400 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                </div>}
                            </div>
                        </UpAnimation>

                    </div>
                </div>
            </div>
        </>
    )
}