import React, {useState, onUpdate, useEffect} from 'react';
import { useSpring, animated } from 'react-spring';
import ErrorMessage from './errorHandlingMsg.js';
import { useBudget } from '../hooks/budgetDataContext.js';

import useBudgetData from '../hooks/userBudgetData.js';

const ExpensesTable = ({ data, className, isNewExpense, setIsNewExpense, budgetData, proj_expense, setProj_Expense, setEditProjected }) => {
  const [currentEdit, setCurrentEdit] = useState(null)
  const { updateProjected, updateCurrent } = useBudget();
  const [currentEditValue, setCurrentEditValue] = useState(null)
  const [projectedEditValue, setProjectedEditValue] = useState(null)
  const [isError, setIsError] = useState(false); // Error state
  const [isEdit, setIsEdit] = useState(
    {
      current : false, 
      projected : false
    }
  )
    console.log(data)
  const handleEdit = (value, name, current, projected ) => {
    if (value === null) {
        if ((currentEditValue?.replace(/\D/g, '') !== current) && (projectedEditValue?.replace(/\D/g, '') !== projected)) {
            handleSubmit(name)
        }
    }
    setIsEdit(prevState => ({
      current: false,
      projected:false
    }));
    setCurrentEdit(value);
  };

   const handleCurrentEdit = (e, current, original) => {
    setCurrentEditValue(e.target.value);
    if(e.target.value === "$ "+original) {
      setIsEdit({
        current: false,
        projected:false
    });
    }
    else {
    setIsEdit({
      current: true,
      projected:false
  });
}
  };

  const handleProjEdit = (e, current, original) => {
    setProjectedEditValue(e.target.value)
    if(e.target.value === "$ "+original) {
      setIsEdit({
        current: false,
        projected:false
    });
    }
    else {
      setIsEdit({
        current: false,
        projected: true
      });
  }
  }

  
  const clickInput = (type) => {
    if (type === "C"){
      if (isEdit.projected === true) {
        setIsError("Please Submit or Undo Changes")
      }
    }
    if (type === "P"){
      if (isEdit.current === true) {
        setIsError("Please Submit or Undo Changes")
      }
    }
  }
  // Handles the submission of the edited value
  const handleSubmit = (item) => {
    // Extract the numerical value and update the item's current price    
    const crntVal = currentEditValue?.replace(/^\$/, ''); // Remove $ if present
    const prjVal = projectedEditValue?.replace(/^\$/, ''); // Remove $ if present
    const isPrjValValid = prjVal && !isNaN(prjVal);
    const isCrntValValid = crntVal && !isNaN(crntVal);
    
    // Based on the validity of the inputs, perform actions.
    if (isPrjValValid || isCrntValValid) {
        if (isCrntValValid) {
            updateCurrent(currentEdit, crntVal, budgetData.monthlyExpenses, setIsNewExpense, isNewExpense)
            setCurrentEditValue(null);
            setCurrentEdit(null)
        }
        if (isPrjValValid) {
            updateProjected(currentEdit, prjVal, data, budgetData.monthlyExpenses, proj_expense, setProj_Expense, setIsNewExpense, isNewExpense)
            setEditProjected(true)
            setProjectedEditValue(null);
            setCurrentEdit(null)
        }

      
      // If both are valid, 'setCurrentEditValue' will also be nullified above.
      // If you need to distinguish actions strictly when both are valid,
      // additional checks or restructuring may be required based on exact needs.
      
    } else {
      console.log("ERROR");
      setIsError(true);
    }
  };

  // This function returns the animation properties based on the item being edited or not
  const editAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    // This part ensures the animation plays each time the component is clicked (or the state changes)
});
  
  return (
    <>
    {/* {isError && (
        <div className='w-screen hidden h-screen top-0 absolute border-2 border-black'>
            <div>Test</div>
            <ErrorMessage message={"Please enter a number"} handleSubmit={completeError}/>
        </div>
    )} */}
    <div className={`overflow-x-auto ${className}`}>
      <table className="min-w-full divide-ydivide-gray-200">
        <thead className="bg-gray-50">
          <tr className='h-3/4'>
            <th scope="col" className="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Category
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Item
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Current Expense
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Projected Expense
            </th>
            <th scope="col" className="pr-4 py-3 flex justify-center items-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Edit
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Object.entries(data).map(([category, items]) => (
            items.map((item, index) => (
              <tr key={index}>
                {index === 0 && <td className="px-6 py-4 font-noto font-bold text-md text-gray-600" rowSpan={items.length}>{category}</td>}
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {currentEdit === item?.name ? (<input onChange={(e) => handleCurrentEdit(e, item?.name, item?.current)} disabled={isEdit.projected} onClick={() => clickInput("C")} type="text" className={`w-1/2 border-blue-600 ${isEdit.projected && "enabled:bg-gray-200 bg-gray-200 opacity-50 cursor-not-allowed  "} rounded-lg border-2`} defaultValue={`$ ${item.current}`} />
                ) : (
                    <>$ {item.current}</>
                )}
                </td>                
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {currentEdit === item?.name ? (<input onChange={(e) => handleProjEdit(e, item?.name, item?.projected)} disabled={isEdit.current} onClick={() => clickInput("P")} type="text" className={`w-1/2 border-blue-600 ${isEdit.current && "enabled:bg-red-600 bg-gray-200 opacity-50 cursor-not-allowed "} rounded-lg border-2`} defaultValue={`$ ${item.projected}`} />
                ) : (
                    <>$ {item.projected}</>
                )}
                </td>
                <td className='pr-4 py-3 flex justify-center items-center whitespace-nowrap text-sm text-gray-700'>
                    {
                        (currentEdit !== item?.name) ? 
                            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" onClick={() => handleEdit(item?.name, null)} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:scale-105 transition duration-500 cursor-pointer"><path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" /></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" onClick = {() => handleEdit(null, item?.name, item?.current, item?.projected)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:scale-105 transition duration-500 cursor-pointer rotate-180">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    }
                </td>
              </tr>
            ))
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default ExpensesTable;
