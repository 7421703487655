import React, {useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import Finbot from "../sections/finbot.js"
import StockBoard from "../sections/stockboard.js"
import { db } from '../firebase/auth.js';
import Budgeting from '../sections/bugdeting.js'
import AccountPage from '../sections/accountpage.js'
import useDarkMode from '../hooks/useDarkMode.js'
import { UserContext } from '../hooks/UserContext.js';
import HamburgerNav from '../components/hamburger.js';
import Initialize from '../sections/initialize.js';
import Account from '../components/account.js';
import Sidebar from '../components/sidenav.js';
import { useCheckout } from '../components/checkoutButton.js';
import Logo from '../images/logo.png';
import LogoDark from '../images/logo_dark.png';


function Dashboard() {
    const navigate = useNavigate();
    const [theme, setTheme] = useDarkMode()
    const { logout, userQualities, currentUser, changeUserValue } = useContext(UserContext);
    const { sendToCustomerPortal, getPremiumStatus } = useCheckout();
    const [currentPage, setCurrentPage] = useState("Budgeting")
    const [isOpen, setIsOpen] = useState(false)
    const [budgetData, setBudgetData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    const menus = [
      {link : "#dashboard", name : "Budgeting", icon : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" /></svg>},
      {link : "#finbot", name: "Finbot", icon : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" /></svg>},
      {link : "#account", name : "Account", icon : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>}
  ]
    const changePage = (page, bd) => {
      if (bd) {
        setBudgetData(bd)
        setCurrentPage(page)
      }
      else {
        setCurrentPage(page)
      }
    }
    
    const handleOpenCustomerPortal = () => {
      sendToCustomerPortal();
    }

    const routePortal = () => {
      if (userQualities?.tier !== "free") { 
          sendToCustomerPortal()
      }
      else {
        navigate("/")
      }
      setIsDisabled(true)
      setLoading(true)
  }

    const renderPage = () => {
      switch (currentPage) {
        case "Finbot":
          return <Finbot />
        case "Budgeting":
          if (userQualities.initialize === false) {
            return <Initialize />
          }
          return <Budgeting changePage={changePage} />
        case "Set Budget":
          return <Finbot budgeting={true} budgetData={budgetData}/>
        case "Account":
          return <AccountPage />
        default:
          break;
      }
    }

    const closeMenu = (state) => {
      setIsOpen(state)
    }

    useEffect(() => {
      if (userQualities) {
        const checkPremiumStatus = async () => {
          try {
            const status = await getPremiumStatus();
            console.log(status, "STAtus")
            if (userQualities.tier !== "premium" && status?.status === "active") {
              await changeUserValue(currentUser.uid, "tier", "premium")
            }
          } catch (error) {
            console.error("Error checking premium status:", error.message);
          } finally {
          }
        };
        checkPremiumStatus();
        console.log("user qualities changed", userQualities)
        // Perform actions that depend on userQualities
      }
    }, [userQualities]); 

    useEffect(() => {
    }, [currentPage])

  return (
    <>
<div className="flex flex-col bg-gray-200 w-full min-h-screen">
<div className='divToExpand flex flex-grow flex-row relative w-full max-w-full dark:bg-[#0e131a] transition duration-500 font-noto max-h-full'>
      <div className='flex flex-grow z-50 absolute lg:relative'><Sidebar menus={menus} changePage={changePage} closeMenu = {closeMenu} currentPage={currentPage} /></div>
      <div className={`w-full xl:pl-4 flex flex-grow min-h-full flex-col mx-4`}>
          <div className='flex mt-4 flex-row w-full justify-between flex-wrap'>
              <div className='flex flex-col flex-grow-0 pb-5 ml-4'>
                  <div className='text-black ml-7 dark:text-white text-xl '>Welcome back, </div>
                  <div className='text-black ml-7 dark:text-white text-4xl font-bold '>{userQualities && userQualities.name}</div>
              </div>
              <img src={Logo} className='absolute md:relative top-0 mr-2 md:mr-0 right-0 dark:hidden mt-2 w-10 h-7 md:w-20 md:h-14'/>
              <img src={LogoDark} className='absolute md:relative top-0 right-0 mr-2 md:mr-0 hidden dark:flex mt-2 w-10 h-7 md:w-20 md:h-14'/>
              {userQualities && (userQualities.tier !== "premium" && (
                  <div onClick={!isDisabled ? () => routePortal() : null} className={`flex flex-row ${loading ? "disabled opacity-50 cursor-progress " : "cursor-pointer"} gap-2 bg-gradient-to-r from-blue-600 to-indigo-500 h-12 mb-5 text-xs md:text-base text-white my-auto mx-auto md:mx-0 md:mr-10 px-8 rounded-xl font-bold justify-center  transition duration-500 hover:scale-105 items-center`}>
                      <div>Upgrade</div> 
                      {/* SVG Icon */}
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                      </svg>

                  </div>
              ))}
          </div>

          <div className='w-full h-full flex '>
            {renderPage()}
          </div>
      </div>  
    </div>
  </div>
    </>
  )
}
export default Dashboard