import React, { useContext, useEffect, useState, useRef, location } from 'react';
import { UserContext } from '../hooks/UserContext.js';
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from 'react-spring';
import Fin from "../images/lightFin.webp"
import DarkFin from "../images/finDark.webp"
import useDarkMode from '../hooks/useDarkMode.js';
import useCollection from '../hooks/useCollection.js';
import {useBudget} from "../hooks/budgetDataContext.js"
import HamburgerNav from '../components/hamburger.js';
import useGPT from '../hooks/useGPT.js';
import { AnimatePresence, motion } from "framer-motion";

export default function Finbot({budgeting, budgetData}) {
    // {!budgeting && ...}
    // TODO: Optimize the amount of reads- 30 reads just on this load
    const MAX_HEIGHT = 100;
    const navigate = useNavigate();
    const { createBudget } = useBudget();
    const { currentUser, loading, userName, logout, userQualities, checkAmount } = useContext(UserContext);
    const { addToSession, pastSessions, pullSessions, createSession } = useCollection(currentUser);
    const {messages, setMessages, typing, setTyping, inputMessage, setInputMessage, processingMessageToFinbot} = useGPT(currentUser, budgeting)
    const [prevSession, setPrevSession] = useState()
    const [id, setId] = useState(null)
    const [preview, setPreview] = useState(false)
    const [theme, setTheme] = useDarkMode()
    const endOfMessagesRef = useRef(null);
    const [mobileOptions, setMobileOptions] = useState(false)
    const [finbotBudget, setFinBotBudget] = useState(null)
    // So it doesnt pull from DB every time- wasting reads 
    const [pulledSessions, setPulledSessions] = useState(false)
    const samplePrompts = [
        "What's the current stock price of Apple?",
        "Can you give me the latest on cryptocurrency trends?",
        "How should I start investing with a low budget?",
        "What are the best stocks to invest in right now?"
    ];
    const [randomPrompts, setRandomPrompts] = useState([]);
    const [showPrompts, setShowPrompts] = useState(true);
    
    useEffect(() => {
        // Function to get 4 unique random prompts
        const getRandomPrompts = (prompts, count) => {
            let shuffled = [...prompts].sort(() => 0.5 - Math.random());
            return shuffled.slice(0, count);
        };
    
        setRandomPrompts(getRandomPrompts(samplePrompts, 4));
        const removeCategories = (data) => {
            console.log(data, ":Data")
            // Clone the data to avoid mutating the original object
            let clonedData = JSON.parse(JSON.stringify(data));
            console.log(clonedData, "CLONED DATA")
            // Iterate over monthlyExpenses and remove the specified fields
            clonedData.monthlyExpenses = clonedData.monthlyExpenses?.map(expense => {
              delete expense.category;
              delete expense.detailed_category;
              return expense;
            });
          
            return clonedData;
          };
          if (budgetData) {
              const newBudget = removeCategories(budgetData)
              setFinBotBudget(newBudget)
          }
    }, []);

    const handlePromptClick = (prompt) => {
        // Set the input message to the selected prompt
        setInputMessage(prompt);
    
        // Optionally, send the message directly
        // For direct sending, mimic the process in your handleSend function
        // handleSend(prompt); // Ensure handleSend can optionally take a message argument
    
        // Hide the prompts
        setShowPrompts(false);
    };
    
    // When theme is true, it is in dark mode
    const chatBotAnimation = useSpring({
        to: {
          width: preview ? '80%' : '95%', // Adjust these values as needed for your layout
        },
        from: {
          width: '95%', // Starting width, adjust as needed
        },
      });
    
    const handleInputChange = (event) => {
        const textarea = event.target;
        setInputMessage(textarea.value);
        textarea.style.height = 'auto';
        const newHeight = Math.min(textarea.scrollHeight, MAX_HEIGHT);
        textarea.style.height = `${newHeight}px`;
      };      

    const handleSend = async () => {
        if (userQualities?.tier === "free") {
            // Call check amount
            checkAmount()
        }
        if (!inputMessage.trim()) return;
        const newMessage = {
            message: inputMessage,
            sender: "user",
            direction: "outgoing"
        };
        setShowPrompts(false);
        setMessages([...messages, newMessage]);
        setInputMessage('');
        setTyping(true);
        if (budgeting) {
            console.log("Sending with budget data")
            await processingMessageToFinbot([...messages, newMessage], finbotBudget.monthlyExpenses);
        }
        else{ 
            await processingMessageToFinbot([...messages, newMessage]);
        }
    };
    
    useEffect(() => {
        console.log("use effect finbot")
        console.log(messages)
        if (messages[messages.length-2]?.message.toLowerCase() === "select budget" && messages[messages.length-2]?.sender === "user") {
            console.log(messages[messages.length-1]?.message, "Budget Plan")
            createBudget(messages[messages.length-1]?.message)
        }
        const sendMessage = async () => {
            // TODO: When user refreshed with only 2 stored in session- it breaks. 
            if (messages.length === 2){
                const new_id = await createSession(messages)
                setId(new_id)
                setPrevSession({id:new_id})
                addToSession(messages, id)
                setPulledSessions(false)
            }
            else if (messages.length > 2){
                if(id != prevSession.id || id == null ){
                    // The user clicked on a previous chat session
                    addToSession(messages, prevSession.id)
                    setPulledSessions(false)
                }
                else {
                addToSession(messages, id)
                setPulledSessions(false)
                }
            }
        }
        sendMessage()
        if(!pulledSessions){
            console.log("Pulling sessions finbot")
            pullSessions()
            setPulledSessions(true)
        }
    }, [messages])

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' }); // Scrolls when a new message is posted
        if (!loading) {
            if (currentUser && !currentUser.emailVerified) {
                navigate('/verify');
            } else if (!currentUser) {
                navigate('/login');
            }
        }
    }, [currentUser, loading, navigate, messages]);

    const newChat = () => {
        setShowPrompts(true);
        let inital_message = budgeting ? "Hey there! Let me help you set up a budget. What are some goals you have for your future financial standings?" : "Hi, I'm Fin! Ask me anything about finance, stocks, investments, etc."
        setMessages([{message: inital_message, sender: "Finbot"}])
        setId(null)
    }

    const displayMessages = () => {
        return (
            <div className = "flex flex-reverse">
            <ul className=' w-full h-full'>
                {messages.map((message, index) => (
                    <React.Fragment key={index}>
                    <li key={index} className={`p-2 text-sm ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                            <div className='text-sm dark:text-gray-100'>{message.sender === "user" ?  `${userName}`  : "Fin"}</div>      
                        <span className={`inline-block p-2 mt-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-300' : 'bg-gray-200'}`}>
                            {message.message}
                        </span>
                    </li>
                    </React.Fragment>
                ))}
                {/* Uncomment when in section<div ref={endOfMessagesRef} /> */}
            </ul>
            </div>
        )
    }

    const togglePreview = () => {
        setPreview(!preview);
    };    

    const isOpeningConfig = {
        tension: 800, // Configuration for opening
        friction: 120, // Adjust friction for opening
        immediate: true
      };
      
    const isClosingConfig = {
    tension: 800, // Lower tension for a slower start
    friction: 200, // Higher friction to slow down the movement more quickly
    };
    
    // Define the animation
    const slideInAnimation = useSpring({
        to: { 
          transform: preview ? 'translateX(0%)' : 'translateX(100%)', // Slides in when preview is true, out when false
          opacity: preview ? 1 : 0,
        },
        from: { transform: 'translateX(100%)', opacity: 0 },
        config: preview ? isOpeningConfig : isClosingConfig,
      });


    return (
        <>
        <div className='flex flex-row w-full h-screen lg:h-full rounded-lg'>
            <div className='w-full flex flex-grow relative flex-col md:flex-row h-[85%] md:h-[90%] lg:h-full'>
                <div className='dark:bg-[#1f2937] mb-3 transition duration-500 bg-white w-[95%] h-[95%] md:h-[95%] rounded-lg mx-auto relative flex flex-col shadow-xl'>
                <div className='w-full h-[40rem] flex justify-between flex-col overflow-auto flex-reverse border-black pt-2 px-5'>
                {displayMessages()}
                    {typing && 
                        <li className="flex flex-row animate-fadeIn ml-2">
                            <img src={theme ? DarkFin: Fin} className='w-[15%] sm:w-[10%] lg:w-[6%] pb-5 sm:pb-2 '/>
                            <span className="rounded-lg flex w-12 lg:w-16 h-8 lg:h-10 relative bottom-5 right-2 lg:right-1 justify-center align-items-center border-black dark:border-gray-100 border-2">
                                    <div className='flex space-x-1 justify-center align-items-center my-auto'>
                                        <div className="w-2 h-2 bg-black dark:bg-white rounded-full animate-dot-fluctuate delay-delay-600" ></div>
                                        <div className="w-2 h-2 bg-black dark:bg-white rounded-full animate-dot-fluctuate delay-delay-500"></div>
                                        <div className="w-2 h-2 bg-black dark:bg-white rounded-full animate-dot-fluctuate delay-delay-800"></div>
                                    </div>
                                </span>
                        </li>
                    }

                </div>
                    {!budgeting && showPrompts && (
                        <div className="flex flex-row lg:grid lg:grid-cols-2 lg:gap-4 overflow-x-auto overflow-y-hidden mb-auto text-left w-full lg:mx-auto lg:w-[75%] mb-1 h-1/2 lg:h-1/4 px-2 lg:mb-4">
                            {randomPrompts.map((prompt, index) => (
                                <button
                                    key={index}
                                    className="opacity-50 hover:opacity-100 justify-end place-self-end hover:scale-105 bg-gray-200 text-xs hover:bg-[#4581f4] mr-2 dark:text-gray-500 dark:hover:bg-finblue hover:border-none dark:hover:border-finblue dark:hover:text-white hover:text-white duration-500 transition duration-500 font-bold lg:py-2 px-4 rounded-lg h-1/4 lg:h-1/2 w-full "
                                    onClick={() => handlePromptClick(prompt)}
                                >   
                                <div className='w-full truncate text-nowrap'>
                                    {prompt}
                                </div>
                                </button>
                            ))}
                        </div>
                    )}
                    { (userQualities?.counter < 30 || userQualities?.tier === "premium") ?
                            <div className = "flex w-full flex-row justify-center items-end mt-auto pb-4">
                                <div className="flex w-2/3 pt-2">
                                    <textarea
                                        rows="1"
                                        className="dark:border-0 border-2 border-gray-300 w-full p-2 bg-white transition duration-500 opacity-65 resize-none outline-none placeholder:text-gray-400 rounded-lg"
                                        style={{ overflowY: 'auto', height: 'auto' }} // Ensuring initial auto height
                                        value={inputMessage}
                                        onChange={handleInputChange}
                                        placeholder={"Ask me anything..."}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault(); // Prevent the default action to avoid newline
                                                handleSend(); // Call your send function
                                            }
                                        }}
                                    />
                                </div>
                                <button className='ml-2 hover:bg-[#4581f4] dark:hover:bg-finblue hover:border-[#4581f4] dark:hover:border-finblue hover:text-white duration-500 bg-transparent dark:text-white transition duration-500 text-gray-400 font-bold py-2 px-4 rounded-lg border-2' style={{ height: '40px' }} onClick={handleSend}> &#8593; </button>
                            </div> 
                            :
                            <div className='text-red-600 font-comfortaa text-center'>
                                You have reached your limit, please upgrade to premium to use Fin!
                            </div>
                        }
                    {/* Toggle Button for Prev Sessions */}
                <button
                    onClick={togglePreview}
                    className="hidden md:flex absolute right-4 bottom-4 p-2 text-white rounded-lg hover:scale-110 duration-500 bg-[#4581f4] dark:bg-finblue transition duration-500"
                >
                <div className='flex flex-row'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
                    </svg>
                    {preview ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-6 0 36 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                    </svg>
                    ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-6 0 36 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    )}
                </div>

                </button>
                </div>
                
                <div className='hidden md:flex w-5'></div>
                

                {/* Conditionally render Prev Sessions based on preview state */}
                {preview && (
                
                <animated.div style={slideInAnimation} className='md:flex absolute lg:relative right-0 top-0 flex-col align-center justify-center w-1/2 md:w-1/5 h-2/3 lg:h-[95%]'>
                    <div className="flex flex-row h-1/6 items-center mb-5 dark:bg-[#1f2937] transition duration-500 bg-white rounded-lg z-20 shadow">
                            <div className='flex hover:scale-105 duration-500 cursor-pointer w-3/5 mx-auto h-1/2 justify-center font-comfortaa text-center bg-[#E4E7EB] dark:bg-white transition duration-500 dark:text-black transition-duration-500 rounded-lg text-white'><button onClick = {newChat} className='text-black transition duration-500' >New Chat</button></div>
                        </div>
                    
                    <div className='sm:flex-row md:flex h-full overflow-auto md:flex-col w-full py-5 rounded-lg dark:bg-[#1f2937] transition duration-500 bg-white shadow-lg'>

                        {/* Prev Sessions */}
                            {pastSessions && pastSessions.length > 0 ? 
                                pastSessions.slice(0,(preview ? pastSessions.length : 5)).map((session, idx) => (
                                    <div className='w-full my-2 text-white'>
                                        <div className='w-3/4 mx-auto'>
                                            <p onClick={() => {setPrevSession(session); setMessages(session.texts); setPreview(!preview)}} className='hover:scale-110 duration-500 cursor-pointer justify-center bg-[#4581f4] text-white dark:text-black transition duration-500 dark:bg-finblue transition duration-500 p-4 text-center overflow-hidden truncate rounded-lg w-full text-xs text-black dark:text-white font-comfortaa break-words'>
                                                {session.texts[session.texts.length - 2].message}
                                            </p>
                                        </div>
                                    </div>
                                )) 
                                :
                                <div></div>   
                            }
                    </div>
                </animated.div>
                )}
                <div className='flex md:hidden w-[95%] rounded-lg py-2 h-full bg-white dark:bg-[#1f2937] mx-auto shadow-lg'>
                    <button
                        onClick={togglePreview}
                        className="grid items-center text-xs sm:text-sm flex md:hidden mx-auto w-1/3 h-full p-2 text-white rounded-lg hover:scale-110 duration-500 bg-[#4581f4] dark:bg-finblue transition duration-500"
                    >{preview ? 'Hide Chats' : 'Show Chats'}</button>
                </div>
            </div>
        </div>
        </>
    );
    
}